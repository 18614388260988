import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

export const AdminAddUser = () => {
  return (
    <Layout route="admin-users" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/admin`} className="back">Back</Link>
            <h1>Add New User</h1>
          </div>

          <section>
            <div className="form">
              <Form.Control type="text" placeholder="Email" />
              <Form.Control as="select">
                <option>Viewer</option>
                <option>Editor</option>
                <option>Admin</option>
              </Form.Control>
              <Link to={`/admin/users`} className="btn">
                Invite
              </Link>
            </div>
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

export default AdminAddUser;
