import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import colorGradient from '../../../highcharts/gradients';
import BudgetChart from '../../../highcharts/BudgetChartV2';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSummary: true,
      summaryUnits: 'percent',
      summaryTimeframe: 12
    };
  }
  render() {
    const { match, projects } = this.props;
    const { showSummary, summaryUnits, summaryTimeframe } = this.state;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      health,
      description,
      favorite,
      totalBudget,
      budgetConsumed,
      status,
      lastStatusChange,
      projectLead,
      startDate,
      endDate,
      assignedUsers,
      nowSliceIndex,
      endSliceIndex,
      nowSliceIndexTier1,
      projectDescription
    } = project || {};

    const carousel = (projects || []).filter(p => p.favorite == favorite);
    const index = (projects || []).indexOf(project);
    const prevIndex = index > 0 ? carousel[index - 1].id : id;
    const nextIndex = index < carousel.length - 1 ? carousel[index + 1].id : id;

    const toggleSummary = () => this.setState({ showSummary: !showSummary });
    const setSummaryUnits = v => this.setState({ summaryUnits: v });
    const setSummaryTimeframe = v => this.setState({ summaryTimeframe: v });

    return (
      <Layout route="project-details">
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <button
                type="button"
                className={`toggle-summary ${!showSummary ? 'down' : 'up'}`}
                onClick={toggleSummary}
              />
              <h3>Summary Chart</h3>
              <Collapse in={showSummary}>
                <div>
                  <div className="summary-timeframe">
                    <Button
                      onClick={() => {}}
                      className={summaryTimeframe === 1 ? 'active' : ''}
                      disabled={true}
                    >
                      1M
                    </Button>
                    <Button
                      onClick={() => {}}
                      className={summaryTimeframe === 3 ? 'active' : ''}
                      disabled={true}
                    >
                      3M
                    </Button>
                    <Button
                      onClick={() => setSummaryTimeframe(6)}
                      className={summaryTimeframe === 6 ? 'active' : ''}
                    >
                      6M
                    </Button>
                    <Button
                      onClick={() => setSummaryTimeframe(12)}
                      className={summaryTimeframe === 12 ? 'active' : ''}
                    >
                      1Y
                    </Button>
                  </div>
                  <BudgetChart
                    projectData={project}
                    asPercent={summaryUnits === 'percent'}
                    containerName={'summary-chart'}
                    backgroundColor={'transparent'}
                    height={'250px'}
                    width={'100%'}
                    summaryTimeframe={summaryTimeframe}
                  />
                  <div className="summary-legend">
                    <div className="actual">
                      <span /> Actual
                    </div>
                    <div className="forecast">
                      <span /> Forecast
                    </div>
                    <div className="budget">
                      <span /> Budget
                    </div>
                  </div>
                  <div className="summary-units">
                    <Button
                      onClick={() => setSummaryUnits('percent')}
                      className={summaryUnits === 'percent' ? 'active' : ''}
                    >
                      Percent (%)
                    </Button>
                    <Button
                      onClick={() => setSummaryUnits('currency')}
                      className={summaryUnits === 'currency' ? 'active' : ''}
                    >
                      Currency ($)
                    </Button>
                  </div>
                </div>
              </Collapse>

              <Row>
                <Col>
                  <div className="budget bg">
                    <h3>Budget</h3>
                    <div>
                      <strong>{numeral(totalBudget).format('$0a')}</strong>
                      <div>Total Budget</div>
                    </div>
                    <div>
                      <strong>{numeral(budgetConsumed).format('$0a')}</strong>
                      <div>Budget Consumed</div>
                    </div>
                    <div className="bar">
                      <div
                        style={{
                          width: numeral(budgetConsumed / totalBudget).format(
                            '0.0%'
                          )
                        }}
                      >
                        {numeral(budgetConsumed / totalBudget).format(
                          '0%'
                        )}&nbsp;
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="status bg">
                    <h3>Status</h3>
                    <span>{status}</span>
                    <small>
                      {lastStatusChange}
                      {' '}
                      day
                      {`${lastStatusChange > 1 ? 's' : ''}`}
                      {' '}
                      since last status change
                    </small>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="timeline bg">
                    <h3>Timeline</h3>
                    <strong>
                      {moment(endDate).diff(moment(startDate), 'months') + 1}
                      {' '}
                      months
                    </strong>
                    <div className="details">
                      <div>
                        <b>{moment(startDate).format('MMM YYYY')}</b>
                        <span>Start</span>
                      </div>
                      <div>
                        <b>{moment(endDate).format('MMM YYYY')}</b>
                        <span>End</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Link className="health bg" to={`/projects/${id}/health`}>
                    <h3>Project Health</h3>
                    <div style={{ color: colorGradient(health / 100) }}>
                      {health}
                    </div>
                  </Link>
                </Col>
              </Row>

              <div className="budget-projection bg">
                <h3>Budget Projection</h3>
                <p>
                  Project is currently projecting to be completed under budget and ahead of schedule.
                </p>
              </div>

              <div className="project-lead bg">
                <span>{projectLead}</span>
                <h3>Project Lead</h3>
                <div className="actions">
                  <button className="btn email">
                    Email
                  </button>
                  <button className="btn phone">
                    Phone
                  </button>
                  <button className="btn message">
                    Message
                  </button>
                </div>
              </div>

              <div className="details">
                <h3>Project Details</h3>
                <p>
                  {projectDescription}
                </p>
              </div>

            </section>

          </Col>
        </Row>
        <div className="project-carousel">
          <div>
            <Link to={`/projects/${prevIndex}`}>Prev</Link>
            <ul>
              {(carousel || []).map((u, index) => (
                <li key={index}>
                  <Link to={`/projects/${u.id}`}>
                    <span className={id == u.id ? 'active' : ''} />
                  </Link>
                </li>
              ))}
            </ul>
            <Link to={`/projects/${nextIndex}`}>Next</Link>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectDetails);
