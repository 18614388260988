import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const UpdatePasswordForm = props => {
  const { handleSubmit, submitting, password, passwordConfirm } = props;
  const regex = /[^\p{L}\s@#]/u;
  const doesPwnMatch = password === passwordConfirm ? true : false;
  const isPwnAtLeast8Chars = (password || '').length >= 8 ? true : false;
  const doesContainNumOrSym = regex.test(password);
  return (
    <form onSubmit={handleSubmit}>
      <h2>Update password</h2>
      <p className="help">
        Must include at least one symbol or number and have at least 8 characters.
      </p>
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        label="Password"
        validate={[required]}
        icon="key"
        insetLabel
      />
      {password &&
        <div className="password-req">
          <div className={(password || '').length >= 8 ? 'valid' : ''}>
            Password strength:
            {(password || '').length < 8 &&
              <strong className="red"> weak</strong>}
            {(password || '').length >= 8 &&
              (password || '').length < 10 &&
              <strong className="orange"> medium</strong>}
            {(password || '').length >= 10 &&
              <strong className="green"> strong</strong>}
          </div>
          <div className="valid">
            Can’t contain your name or email address
          </div>
          <div className={isPwnAtLeast8Chars ? 'valid' : ''}>
            At least 8 characters
          </div>
          <div className={doesContainNumOrSym ? 'valid' : ''}>
            Contains a number or symbol
          </div>
        </div>}
      <Field
        name="passwordConfirm"
        type="password"
        component={FormField.Input}
        label="Re-enter password"
        validate={[required]}
        icon="key"
        insetLabel
      />
      {passwordConfirm &&
        !doesPwnMatch &&
        <div className="password-req">
          <div>
            Passwords don’t match
          </div>
        </div>}
      <div>
        <Button variant="primary" type="submit" disabled={submitting}>
          Update
        </Button>
      </div>
    </form>
  );
};

const formName = 'update-password';
const selector = formValueSelector(formName);

const mapState = state => {
  const password = selector(state, 'password');
  const passwordConfirm = selector(state, 'passwordConfirm');
  return {
    password,
    passwordConfirm
  };
};

const form = reduxForm({
  form: formName
})(UpdatePasswordForm);

export default connect(mapState)(form);
