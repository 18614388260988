import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { actionCreators } from '../../state/demo-dux';

import NavItemLink from './NavItemLink';

const BottomNav = ({ user, notifications }) => {
  const { groups } = user || {};

  const isTier2 = (user.groups || []).includes('tier-2');

  const filteredNotifications = (notifications || []).filter(({tier})=>{
    return isTier2 ? tier === 2 : tier === 1
  });

  const isAdmin = groups.includes('admin');
  return (
    <Navbar fixed="bottom">
      <Nav>
        <NavItemLink className="projects" to="/">Projects</NavItemLink>
        <NavItemLink className="search" to="/search">Search</NavItemLink>
        <NavItemLink className="notifications" to="/notifications">
          {(filteredNotifications || []).length > 0 &&
            <span className="badge">{(filteredNotifications || []).length}</span>}
          Notifications
        </NavItemLink>
        {isAdmin &&
          <NavItemLink className="admin" to="/admin">Admin</NavItemLink>}
        <NavItemLink className="profile" to="/profile">Profile</NavItemLink>
      </Nav>
    </Navbar>
  );
};

const mapState = state => {
  const user = state.login.toJS().result;
  const notifications = state.demo.toJS().notifications;
  return { user, notifications };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(BottomNav);
