import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

const data = {
  sessionDuration: 7,
  sso: 'Okta'
};

class AdminSecurity extends Component {
  constructor(props) {
    super(props);
    this.state = { reqMultiFactor: true };
  }
  render() {
    const { reqMultiFactor } = this.state;
    const toggleOption = () =>
      this.setState({ reqMultiFactor: !reqMultiFactor });
    return (
      <Layout route="admin-security" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin`} className="back">Back</Link>
              <h1>Security</h1>
            </div>

            <section>
              <div className="group">
                <span>Edit</span>
                <h3>Session Duration</h3>
                <div>{data.sessionDuration} days</div>
              </div>
              <div className="group">
                <span>Edit</span>
                <h3>Single Sign On (SSO)</h3>
                <div>{data.sso}</div>
              </div>
              <div className="group">
                <div>
                  Require Multi-Factor Authentication
                  <div
                    className={`toggle ${reqMultiFactor ? 'active' : ''}`}
                    onClick={toggleOption}
                  />
                </div>
              </div>
            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AdminSecurity;
