import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN'
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN']
  })
};