import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import localForage from 'localforage';
import { actionCreatorsModal } from '../../../state/modals-dux';

const { location } = window || {};

const logout = async () => {
  await localForage.removeItem('identity');
  location.href = '/';
};

const LogoutModal = ({ openModal, closeModal }) => (
  <Modal
    show={openModal === 'LogoutModal'}
    className="modal-confirmation"
    onHide={closeModal}
  >
    <Modal.Body>
      <h4>Are you sure you want to logout?</h4>
      <p>Unsaved changes will be lost.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={closeModal}>
        Cancel
      </Button>
      <Button variant="danger" onClick={logout}>
        Logout
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(actionCreatorsModal, dispatch);

export default connect(mapState, mapDispatch)(LogoutModal);
