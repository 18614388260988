import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import BudgetChart from '../../../highcharts/BudgetChartV2';
import ProjectNav from './ProjectNav';

class ProjectDetailsCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryUnits: 'percent',
      summaryTimeframe: 12
    };
  }
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { summaryUnits, summaryTimeframe } = this.state;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      totalBudget,
      budgetConsumed,
      tier2
    } = project || {};

    const {
      cost
    } = tier2 || {};

    const isNeg = (cost.slices[activeSliceIndex] || {}).valuePlanned -
      (cost.slices[activeSliceIndex] || {}).valueActual <
      0;

    const setSliceIndex = value => setActiveSlice(value);
    const setSummaryUnits = v => this.setState({ summaryUnits: v });
    const setSummaryTimeframe = v => this.setState({ summaryTimeframe: v });
    return (
      <Layout route="project-details-t2 cost" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="cost"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <Row>
                <Col>
                  <div className="budget bg">
                    <h3>Budget</h3>
                    <div>
                      <strong>{numeral(totalBudget).format('$0a')}</strong>
                      <div>Total Budget</div>
                    </div>
                    <div>
                      <strong>
                        {numeral(
                          (cost.slices[activeSliceIndex] || {}).valueActual
                        ).format('$0a')}
                      </strong>
                      <div>Budget Consumed</div>
                    </div>
                    <div className="bar">
                      <div
                        style={{
                          width: numeral(
                            (cost.slices[activeSliceIndex] || {}).valueActual /
                              totalBudget
                          ).format('0.0%')
                        }}
                      >
                        {numeral(
                          (cost.slices[activeSliceIndex] || {}).valueActual /
                            totalBudget
                        ).format('0%')}&nbsp;
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="variance bg">
                    <h3>Variance</h3>
                    <span>
                      {numeral(
                        ((cost.slices[activeSliceIndex] || {}).valuePlanned -
                          (cost.slices[activeSliceIndex] || {}).valueActual) /
                          (cost.slices[activeSliceIndex] || {}).valuePlanned *
                          100
                      ).format('0.00')}
                      %
                    </span>
                    <br />
                    <h3>Cost overage</h3>
                    <div
                      style={{
                        color: isNeg ? '#DF5353' : '#55BF3B',
                        marginBottom: '15px'
                      }}
                    >
                      {(isNeg ? '(' : '') +
                        numeral(
                          (cost.slices[activeSliceIndex] || {}).valuePlanned -
                            (cost.slices[activeSliceIndex] || {}).valueActual
                        ).format('0a') +
                        (isNeg ? ')' : '')}
                    </div>
                  </div>
                </Col>
              </Row>

              <div>
                <div className="summary-timeframe">
                  <Button
                    onClick={() => {}}
                    className={summaryTimeframe === 1 ? 'active' : ''}
                    disabled={true}
                  >
                    1M
                  </Button>
                  <Button
                    onClick={() => {}}
                    className={summaryTimeframe === 3 ? 'active' : ''}
                    disabled={true}
                  >
                    3M
                  </Button>
                  <Button
                    onClick={() => setSummaryTimeframe(6)}
                    className={summaryTimeframe === 6 ? 'active' : ''}
                  >
                    6M
                  </Button>
                  <Button
                    onClick={() => setSummaryTimeframe(12)}
                    className={summaryTimeframe === 12 ? 'active' : ''}
                  >
                    1Y
                  </Button>
                </div>
                <BudgetChart
                  projectData={project}
                  asPercent={summaryUnits === 'percent'}
                  containerName={'summary-chart'}
                  backgroundColor={'transparent'}
                  height={'250px'}
                  width={'100%'}
                  isTier2
                  summaryTimeframe={summaryTimeframe}
                />
                <div className="summary-legend">
                  <div className="actual">
                    <span /> Actual
                  </div>
                  <div className="forecast">
                    <span /> Forecast
                  </div>
                  <div className="budget">
                    <span /> Budget
                  </div>
                </div>
                <div className="summary-units">
                  <Button
                    onClick={() => setSummaryUnits('percent')}
                    className={summaryUnits === 'percent' ? 'active' : ''}
                  >
                    Percent (%)
                  </Button>
                  <Button
                    onClick={() => setSummaryUnits('currency')}
                    className={summaryUnits === 'currency' ? 'active' : ''}
                  >
                    Currency ($)
                  </Button>
                </div>
              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsCost);
