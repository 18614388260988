import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

const data = [
  {
    uid: 1,
    firstName: 'Alex',
    lastName: 'Strebeck',
    email: 'alex.strebeck@redcross.com',
    role: 'viewer'
  },
  {
    uid: 2,
    firstName: 'Matej',
    lastName: 'Janovcik',
    email: 'matej.janovcik@redcross.com',
    role: 'editor'
  },
  {
    uid: 3,
    firstName: 'Ben',
    lastName: 'Bluerock',
    email: 'ben.bluerock@redcross.com',
    role: 'admin'
  },
  {
    uid: 4,
    firstName: 'Ben',
    lastName: 'Redstone',
    email: 'ben.redstone@redcross.com',
    role: 'admin'
  }
];

class AdminUserPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: true,
      success: false
    };
  }
  render() {
    const { match, projects, showModal } = this.props;
    const { option, success } = this.state;
    const { params } = match || {};
    const { uid } = params || {};
    const user = (data || []).find(u => u.uid == uid);
    const toggleOption = () => this.setState({ option: !option });
    const submit = () => this.setState({ success: true });
    return (
      <Layout route="admin-users" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin/users/${user.uid}`} className="back">Back</Link>
              <h1>Users</h1>
            </div>

            <section>
              <div className="user-detail">
                <span>{user.firstName[0]}{user.lastName[0]}</span>
                <div>
                  <strong>{user.firstName} {user.lastName}</strong>
                  <div>{user.email}</div>
                </div>
                <div className={`role ${user.role}`}>{user.role}</div>
              </div>

              {!success &&
                <div className="form">
                  <div className="control-label">Password Reset</div>
                  <Form.Control type="text" value="DIJbL#984Dx" />

                  <div className="group">
                    Require update on next sign in?
                    <div
                      className={`toggle ${option ? 'active' : ''}`}
                      onClick={() => toggleOption()}
                    />
                  </div>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => submit()}
                  >
                    Reset Password
                  </button>

                </div>}

              {success &&
                <div className="success">
                  <p>Password Reset Complete</p>
                  <p>
                    An email with new password has been sent to {user.email}
                  </p>
                  <Link to={`/admin/users/${user.uid}`}>
                    Back to user
                  </Link>
                </div>}

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AdminUserPassword;
