import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

const data = [
  {
    uid: 1,
    firstName: 'Alex',
    lastName: 'Strebeck',
    email: 'alex.strebeck@redcross.com',
    role: 'viewer'
  },
  {
    uid: 2,
    firstName: 'Matej',
    lastName: 'Janovcik',
    email: 'matej.janovcik@redcross.com',
    role: 'editor'
  },
  {
    uid: 3,
    firstName: 'Ben',
    lastName: 'Bluerock',
    email: 'ben.bluerock@redcross.com',
    role: 'admin'
  },
  {
    uid: 4,
    firstName: 'Ben',
    lastName: 'Redstone',
    email: 'ben.redstone@redcross.com',
    role: 'admin'
  }
];

const UsersSearchForm = props => {
  const { handleSubmit, search } = props;
  const results = search
    ? (data || [])
        .filter(p => p.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    : data;
  return (
    <div>

      <form onSubmit={handleSubmit}>
        <Field
          name="search"
          type="text"
          component={FormField.Input}
          label="Search"
          insetLabel
          icon="search"
        />
      </form>

      <section>
        <ul>
          {(results || []).map((u, index) => (
            <li key={index}>
              <Link to={`/admin/users/${u.uid}`}>
                <span>{u.firstName[0]}{u.lastName[0]}</span>
                <div>
                  <strong>{u.firstName} {u.lastName}</strong>
                  <div>{u.email}</div>
                </div>
                <div className={`role ${u.role}`}>{u.role}</div>
              </Link>
            </li>
          ))}
        </ul>
      </section>

    </div>
  );
};

const formName = 'users-search-form';
const selector = formValueSelector(formName);

const mapState = state => {
  const search = selector(state, 'search');
  return {
    search
  };
};

const form = reduxForm({
  form: formName
})(UsersSearchForm);

export default connect(mapState)(form);
