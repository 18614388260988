import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const SearchForm = props => {
  const { handleSubmit, submitting, search, projects, deleteProject } = props;
  const results = search
    ? (projects || [])
        .filter(p => p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    : projects;
  return (
    <div>

      <form onSubmit={handleSubmit}>
        <Field
          name="search"
          type="text"
          component={FormField.Input}
          label="Search"
          insetLabel
          icon="search"
        />
      </form>

      <div className="project-list">
        {(results || []).map((project, index) => (
          <div className="project" key={index}>
            <div className="delete">
              <span onClick={() => deleteProject(project.id)}>-</span>
            </div>
            <div className="project-body">
              <div>
                <strong>{project.name}</strong>
              </div>
              <div>
                <small>{project.description}</small>
              </div>
            </div>
            <div className="move">
              <span />
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

const formName = 'search-form';
const selector = formValueSelector(formName);

const mapState = state => {
  const search = selector(state, 'search');
  return {
    search
  };
};

const form = reduxForm({
  form: formName
})(SearchForm);

export default connect(mapState)(form);
