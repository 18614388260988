import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/demo-dux';

import BottomNav from './BottomNav';

class Layout extends Component {
  render() {
    const { children, route, user, isAuthorized, theme } = this.props;
    return (
      <div
        className={classNames(`route-${route}`, `${theme}-mode`, {
          public: isAuthorized
        })}
      >
        <Container fluid className="layout">
          {children}
        </Container>
        {isAuthorized && <Route component={BottomNav} />}
      </div>
    );
  }
}

const mapState = state => ({
  user: state.login.toJS().result,
  theme: state.demo.toJS().theme
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(Layout);
