import moment from 'moment';

const projects = [
  {
    id: 1,
    health: 93,
    trend: '-',
    favorite: true,
    name: 'Money Train',
    description: 'Payroll & Time Entry System Upgrade',
    nowSliceIndex: 11,
    nowSliceIndexTier1: 7,
    endSliceIndex: 11, //total months will be this number +1, since this is 0 indexed
    totalBudget: 12333810,
    // budgetConsumed: 11339430,
    budgetConsumed: 6000000,
    projectDescription: 'For data security and payroll and time management efficiency and accuracy to meet policy and regulatory standards, an upgrade to the payroll and time tracking systems is being implemented.',
    lastStatusChange: 10,
    projectLead: 'Alex Strebeck',
    assignedUsers: [
      {
        firstName: 'Alex',
        lastName: 'Strebeck',
        email: 'alex.strebeck@corbeauadvisory.com'
      },
      {
        firstName: 'Matej',
        lastName: 'Janovcik',
        email: 'matej.j@corbeauadvisory.com'
      },
      {
        firstName: 'Ben',
        lastName: 'Bluerock',
        email: 'ben.b@corbeauadvisory.com'
      },
      {
        firstName: 'Ben',
        lastName: 'Redstone',
        email: 'ben.r@corbeauadvisory.com'
      }
    ],
    status: 'In-progress',
    startDate: moment()
      .set('date', 1)
      .subtract(11, 'months') /*now slice index*/,
    endDate: moment() /*end slice index*/,
    tier1: {
      overall: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 80,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 92,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 92,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 84,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 87,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 93,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: 90,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 85,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 88,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      scope: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 88,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: 90,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 81,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 81,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      schedule: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 67,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 89,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 58,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 66,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 96,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 83,
            valueForecast: 83,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 98,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 89,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 93,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      cost: {
        //leaving slices in each knowledge area to leave room for other top level info that might be added later for example currentData
        //currentData: this would be an object of the current slice
        unit: 'usd',
        slices: [
          {
            valueBaseLine: 12586118.08,
            valueActual: 0,
            valuePlanned: 0,
            valueActualPercent: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 12586118.08,
            valueActual: 223480,
            valuePlanned: 224440,
            valueActualPercent: 98,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 13200680.89,
            valueActual: 813560,
            valuePlanned: 869040,
            valueActualPercent: 93,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 13416919.02,
            valueActual: 1799585,
            valuePlanned: 1652965,
            valueActualPercent: 92,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 13590887.17,
            valueActual: 2269785,
            valuePlanned: 3227465,
            valueActualPercent: 91,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 13067934.17,
            valueActual: 3674685,
            valuePlanned: 4969605,
            valueActualPercent: 94,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 12869480.5,
            valueActual: 5505825,
            valuePlanned: 5281405,
            valueActualPercent: 96,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 13033944.14,
            valueActual: 7633625,
            valuePlanned: 6672005,
            valueActualPercent: 95,
            valueForecast: 7633625,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 12926696.33,
            valueActual: null,
            valuePlanned: 7296525,
            valueActualPercent: 95,
            valueForecast: 8875545,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 12952881.52,
            valueActual: null,
            valuePlanned: 9693120,
            valueActualPercent: 95,
            valueForecast: 10613790,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 12861016.99,
            valueActual: null,
            valuePlanned: 10744410,
            valueActualPercent: 96,
            valueForecast: 11013790,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: 11330923.67,
            valueActual: null,
            valuePlanned: 12130810,
            valueActualPercent: 109,
            valueForecast: 11339430,
            valueEAC: 13067934.17,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      quality: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 66,
            valueForecast: 66,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 78,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 91,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      resources: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 68,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 85,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 85,
            valueForecast: 85,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 95,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 80,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 90,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      communication: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 80,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      raid: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 55,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 84,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 82,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 79,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 91,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 88,
            valueForecast: 88,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 93,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 65,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 50,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      procurement: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 20,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 99.9,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      stakeholder: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 50,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 75,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          },
          {
            valueBaseLine: null,
            valueActual: null,
            valueForecast: 100,
            date: moment() // +/- number of months relative  to the "now slice" (ex now-1 months) using moment
          }
        ]
      },
      comments: {
        slices: [
          {
            value: ''
          },
          {
            value: 'Off to a slow start (onboarding)'
          },
          {
            value: 'Onboarding Complete'
          },
          {
            value: 'Workshops taking longer than planned'
          },
          {
            value: 'Workshops taking longer than planned'
          },
          {
            value: 'Overtime needed for testing to catch up'
          },
          {
            value: 'Massive overtime to up velocity (higher costs/resource burnout)'
          },
          {
            value: 'Overtime + rushed testing is lowering outcome quality'
          },
          {
            value: 'Many tests have been de-scoped to make up time'
          },
          {
            value: 'Running ORT and simulations (OT and risky)'
          },
          {
            value: 'Failed cutover - added a re-do'
          },
          {
            value: 'Project end.  5% scope shed to protect schedule'
          }
        ]
      }
    },
    //not complete yet with issues and tickets
    tier2: {
      overall: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 80,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 92,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 92,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 84,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 87,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 93,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 85,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 88,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      scope: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months'),
            scope: {
              completed: 8,
              inProgress: 12,
              toDo: 40
            },
            change: {
              total: 3,
              high: 2,
              medium: 1,
              low: 0
            },
            overview: {
              open: 4,
              progress: 2,
              completed: 0,
              review: 0,
              approved: 0
            }
          },
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months'),
            scope: {
              completed: 10,
              inProgress: 15,
              toDo: 35
            },
            change: {
              total: 5,
              high: 2,
              medium: 1,
              low: 2
            },
            overview: {
              open: 6,
              progress: 2,
              completed: 1,
              review: 1,
              approved: 0
            }
          },
          {
            valueBaseLine: null,
            valueActual: 88,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months'),
            scope: {
              completed: 20,
              inProgress: 10,
              toDo: 30
            },
            change: {
              total: 5,
              high: 1,
              medium: 2,
              low: 2
            },
            overview: {
              open: 6,
              progress: 2,
              completed: 1,
              review: 3,
              approved: 0
            }
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months'),
            scope: {
              completed: 22,
              inProgress: 10,
              toDo: 28
            },
            change: {
              total: 6,
              high: 1,
              medium: 2,
              low: 3
            },
            overview: {
              open: 5,
              progress: 2,
              completed: 3,
              review: 1,
              approved: 1
            }
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months'),
            scope: {
              completed: 25,
              inProgress: 13,
              toDo: 22
            },
            change: {
              total: 2,
              high: 2,
              medium: 0,
              low: 0
            },
            overview: {
              open: 2,
              progress: 2,
              completed: 3,
              review: 1,
              approved: 4
            }
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months'),
            scope: {
              completed: 30,
              inProgress: 10,
              toDo: 20
            },
            change: {
              total: 2,
              high: 2,
              medium: 0,
              low: 0
            },
            overview: {
              open: 2,
              progress: 2,
              completed: 3,
              review: 1,
              approved: 4
            }
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months'),
            scope: {
              completed: 35,
              inProgress: 20,
              toDo: 15
            },
            change: {
              total: 6,
              high: 3,
              medium: 1,
              low: 2
            },
            overview: {
              open: 0,
              progress: 2,
              completed: 2,
              review: 4,
              approved: 5
            }
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months'),
            scope: {
              completed: 40,
              inProgress: 13,
              toDo: 7
            },
            change: {
              total: 6,
              high: 3,
              medium: 1,
              low: 2
            },
            overview: {
              open: 0,
              progress: 1,
              completed: 1,
              review: 4,
              approved: 6
            }
          },
          {
            valueBaseLine: null,
            valueActual: 81,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months'),
            scope: {
              completed: 45,
              inProgress: 10,
              toDo: 5
            },
            change: {
              total: 6,
              high: 2,
              medium: 2,
              low: 2
            },
            overview: {
              open: 0,
              progress: 0,
              completed: 2,
              review: 4,
              approved: 6
            }
          },
          {
            valueBaseLine: null,
            valueActual: 81,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months'),
            scope: {
              completed: 45,
              inProgress: 10,
              toDo: 5
            },
            change: {
              total: 6,
              high: 2,
              medium: 2,
              low: 2
            },
            overview: {
              open: 0,
              progress: 0,
              completed: 2,
              review: 4,
              approved: 6
            }
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months'),
            scope: {
              completed: 50,
              inProgress: 10,
              toDo: 0
            },
            change: {
              total: 6,
              high: 2,
              medium: 2,
              low: 2
            },
            overview: {
              open: 0,
              progress: 0,
              completed: 0,
              review: 6,
              approved: 6
            }
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment(),
            scope: {
              completed: 60,
              inProgress: 0,
              toDo: 0
            },
            change: {
              total: 0,
              high: 0,
              medium: 0,
              low: 0
            },
            overview: {
              open: 0,
              progress: 0,
              completed: 0,
              review: 0,
              approved: 12
            }
          }
        ]
      },
      schedule: {
        unit: 'percent',
        bufferMax: 10,
        mileStones: [
          {
            status: [
              'IN PROGRESS',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED'
            ],
            name: 'KICK-OFF & ONBOARDING',
            start: moment().set('date', 1).subtract(11, 'months'),
            end: moment().set('date', 1).subtract(11 - 0.5, 'months')
          },
          {
            status: [
              'NOT STARTED',
              'IN PROGRESS',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED'
            ],
            name: 'DATA CLEANSING',
            start: moment().set('date', 1).subtract(11, 'months'),
            end: moment().set('date', 1).subtract(11 - 2, 'months')
          },
          {
            status: [
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'IN PROGRESS',
              'IN PROGRESS',
              'AT RISK',
              'CRITICAL',
              'IN PROGRESS',
              'IN PROGRESS',
              'COMPLETED',
              'COMPLETED',
              'COMPLETED'
            ],
            name: 'MAPPING, DEVELOPMENT & QA',
            start: moment().set('date', 1).subtract(11 - 2, 'months'),
            end: moment().set('date', 1).subtract(11 - 9, 'months')
          },
          {
            status: [
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'IN PROGRESS',
              'IN PROGRESS',
              'IN PROGRESS',
              'COMPLETED'
            ],
            name: 'TESTING',
            start: moment().set('date', 1).subtract(11 - 10, 'months'),
            end: moment().set('date', 1).subtract(11 - 11, 'months')
          },
          {
            status: [
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'NOT STARTED',
              'COMPLETED'
            ],
            name: 'CUTOVER',
            start: moment().set('date', 1).subtract(11 - 11, 'months'),
            end: moment().set('date', 1).subtract(11 - 11, 'months')
          }
        ],
        slices: [
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            buffer: 10,
            vCommit: 40,
            vCompleted: 48,
            date: moment().set('date', 1).subtract(11, 'months'),
            mileStones: []
          },
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            buffer: 10,
            vCommit: 44,
            vCompleted: 44,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 67,
            valueForecast: null,
            buffer: 5,
            vCommit: 25,
            vCompleted: 38,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 89,
            valueForecast: null,
            buffer: 8,
            vCommit: 30,
            vCompleted: 35,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 58,
            valueForecast: null,
            buffer: 5,
            vCommit: 25,
            vCompleted: 50,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 66,
            valueForecast: null,
            buffer: 3,
            vCommit: 30,
            vCompleted: 45,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 96,
            valueForecast: null,
            buffer: 5,
            vCommit: 40,
            vCompleted: 40,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 83,
            valueForecast: null,
            buffer: 8,
            vCommit: 35,
            vCompleted: 40,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 98,
            valueForecast: null,
            buffer: 8,
            vCommit: 40,
            vCompleted: 40,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 89,
            valueForecast: null,
            buffer: 9,
            vCommit: 38,
            vCompleted: 40,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 93,
            valueForecast: null,
            buffer: 9,
            vCommit: 39,
            vCompleted: 40,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            buffer: 10,
            vCommit: 46,
            vCompleted: 46,
            date: moment()
          }
        ]
      },
      cost: {
        //leaving slices in each knowledge area to leave room for other top level info that might be added later for example currentData
        //currentData: this would be an object of the current slice
        unit: 'usd',
        slices: [
          {
            valueBaseLine: 12586118.08,
            valueActual: 0,
            valuePlanned: 0,
            valueActualPercent: 100,
            valueForecast: null,
            valueActualCost: 0.00,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: 12586118.08,
            valueActual: 223480,
            valuePlanned: 224440,
            valueActualPercent: 98,
            valueForecast: null,
            valueActualCost: 223480.00,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: 13200680.89,
            valueActual: 813560,
            valuePlanned: 869040,
            valueActualPercent: 93,
            valueForecast: null,
            valueActualCost: 881160.00,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: 13416919.02,
            valueActual: 1799585,
            valuePlanned: 1652965,
            valueActualPercent: 92,
            valueForecast: null,
            valueActualCost: 1799585.00,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: 13590887.17,
            valueActual: 2269785,
            valuePlanned: 3227465,
            valueActualPercent: 91,
            valueForecast: null,
            valueActualCost: 2269785.00,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: 13067934.17,
            valueActual: 3674685,
            valuePlanned: 4969605,
            valueActualPercent: 94,
            valueForecast: null,
            valueActualCost: 3674685.00,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: 12869480.5,
            valueActual: 5505825,
            valuePlanned: 5281405,
            valueActualPercent: 96,
            valueForecast: null,
            valueActualCost: 5505825.00,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: 13033944.14,
            valueActual: 6033625,
            valuePlanned: 6672005,
            valueActualPercent: 95,
            valueForecast: null,
            valueActualCost: 6033625.00,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: 12926696.33,
            valueActual: 7675545,
            valuePlanned: 7296525,
            valueActualPercent: 95,
            valueForecast: null,
            valueActualCost: 7675545.00,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: 12952881.52,
            valueActual: 9293845,
            valuePlanned: 9693120,
            valueActualPercent: 95,
            valueForecast: null,
            valueActualCost: 9293845.00,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: 12861016.99,
            valueActual: 10613790,
            valuePlanned: 10744410,
            valueActualPercent: 96,
            valueForecast: null,
            valueActualCost: 10613790.00,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: 11330923.67,
            valueActual: 11339430,
            valuePlanned: 12130810,
            valueActualPercent: 109,
            valueForecast: null,
            valueActualCost: 11339430.00,
            date: moment()
          }
        ]
      },
      quality: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 65,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 78,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 91,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      resources: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 68,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 85,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 85,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 95,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 80,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      communication: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 80,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      raid: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 55,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months'),
            trigger: [0, 1, 5],
            idle: [0, 0, 6],
            age: [0, 0, 6],
            risk: {
              map: [[1, 0, 0], [3, 2, 0], [1, 0, 0]],
              active: [5, 2, 0],
              archived: [6, 2, 0]
            },
            actions: {
              map: [[0, 0, 3], [2, 0, 2], [0, 0, 1]],
              active: [2, 0, 6],
              archived: [5, 1, 4]
            },
            issues: {
              map: [[0, 0, 1], [1, 0, 2], [0, 0, 0]],
              active: [1, 0, 3],
              archived: [0, 1, 2]
            },
            decisions: {
              map: [[1, 0, 0], [4, 2, 0], [0, 2, 0]],
              active: [5, 4, 0],
              archived: [1, 0, 3]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 55,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months'),
            trigger: [0, 1, 5],
            idle: [0, 1, 5],
            age: [0, 1, 5],
            risk: {
              map: [[0, 1, 0], [0, 1, 2], [1, 0, 0]],
              active: [1, 2, 2],
              archived: [0, 2, 4]
            },
            actions: {
              map: [[0, 2, 4], [2, 4, 4], [0, 2, 1]],
              active: [2, 8, 9],
              archived: [2, 2, 8]
            },
            issues: {
              map: [[0, 4, 3], [0, 3, 0], [0, 0, 1]],
              active: [0, 7, 4],
              archived: [2, 0, 2]
            },
            decisions: {
              map: [[0, 2, 1], [3, 2, 0], [1, 1, 0]],
              active: [4, 5, 1],
              archived: [0, 0, 9]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 84,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months'),
            trigger: [1, 1, 4],
            idle: [0, 2, 4],
            age: [1, 1, 4],
            risk: {
              map: [[0, 1, 0], [0, 0, 3], [0, 0, 0]],
              active: [0, 1, 3],
              archived: [1, 2, 6]
            },
            actions: {
              map: [[1, 1, 2], [0, 3, 5], [1, 0, 3]],
              active: [2, 4, 7],
              archived: [3, 2, 13]
            },
            issues: {
              map: [[0, 3, 3], [0, 5, 0], [1, 7, 0]],
              active: [1, 15, 3],
              archived: [3, 0, 3]
            },
            decisions: {
              map: [[0, 1, 3], [0, 3, 0], [0, 0, 0]],
              active: [4, 0, 2],
              archived: [0, 1, 13]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 90,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months'),
            trigger: [1, 2, 3],
            idle: [0, 2, 4],
            age: [1, 2, 3],
            risk: {
              map: [[1, 0, 1], [0, 4, 0], [1, 0, 0]],
              active: [2, 4, 1],
              archived: [0, 2, 0]
            },
            actions: {
              map: [[0, 0, 1], [0, 3, 2], [0, 0, 1]],
              active: [0, 3, 4],
              archived: [2, 2, 4]
            },
            issues: {
              map: [[0, 0, 5], [1, 3, 1], [0, 3, 0]],
              active: [1, 3, 6],
              archived: [1, 0, 2]
            },
            decisions: {
              map: [[0, 1, 0], [7, 2, 0], [0, 1, 1]],
              active: [7, 4, 1],
              archived: [0, 2, 3]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 82,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months'),
            trigger: [1, 2, 3],
            idle: [0, 2, 4],
            age: [1, 2, 3],
            risk: {
              map: [[0, 0, 0], [0, 1, 2], [1, 0, 0]],
              active: [1, 1, 2],
              archived: [1, 2, 4]
            },
            actions: {
              map: [[0, 2, 1], [0, 5, 4], [0, 2, 1]],
              active: [0, 9, 7],
              archived: [3, 2, 10]
            },
            issues: {
              map: [[0, 6, 1], [1, 1, 1], [4, 2, 2]],
              active: [5, 9, 4],
              archived: [2, 0, 2]
            },
            decisions: {
              map: [[0, 1, 2], [3, 3, 0], [0, 2, 0]],
              active: [3, 3, 2],
              archived: [0, 1, 10]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 79,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months'),
            trigger: [1, 5, 2],
            idle: [1, 2, 2],
            age: [1, 2, 5],
            risk: {
              map: [[0, 1, 0], [0, 0, 3], [0, 0, 0]],
              active: [0, 1, 3],
              archived: [1, 2, 6]
            },
            actions: {
              map: [[1, 1, 2], [0, 3, 5], [1, 0, 3]],
              active: [2, 4, 7],
              archived: [3, 2, 13]
            },
            issues: {
              map: [[0, 3, 3], [0, 5, 0], [1, 7, 0]],
              active: [1, 15, 3],
              archived: [3, 0, 3]
            },
            decisions: {
              map: [[0, 1, 3], [0, 3, 0], [0, 0, 0]],
              active: [4, 0, 2],
              archived: [0, 1, 13]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 91,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months'),
            trigger: [2, 1, 5],
            idle: [3, 2, 4],
            age: [3, 1, 4],
            risk: {
              map: [[1, 0, 0], [3, 2, 0], [1, 0, 0]],
              active: [5, 2, 0],
              archived: [6, 2, 0]
            },
            actions: {
              map: [[0, 0, 3], [2, 0, 2], [0, 0, 1]],
              active: [2, 0, 6],
              archived: [5, 1, 4]
            },
            issues: {
              map: [[0, 0, 1], [1, 0, 2], [0, 0, 0]],
              active: [1, 0, 3],
              archived: [0, 1, 2]
            },
            decisions: {
              map: [[1, 0, 0], [4, 2, 0], [0, 2, 0]],
              active: [5, 4, 0],
              archived: [1, 0, 3]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 88,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months'),
            trigger: [2, 1, 4],
            idle: [3, 0, 4],
            age: [1, 4, 1],
            risk: {
              map: [[1, 0, 1], [0, 4, 0], [1, 0, 0]],
              active: [2, 4, 1],
              archived: [0, 2, 0]
            },
            actions: {
              map: [[0, 0, 1], [0, 3, 2], [0, 0, 1]],
              active: [0, 3, 4],
              archived: [2, 2, 4]
            },
            issues: {
              map: [[0, 0, 5], [1, 3, 1], [0, 3, 0]],
              active: [1, 3, 6],
              archived: [1, 0, 2]
            },
            decisions: {
              map: [[0, 1, 0], [7, 2, 0], [0, 1, 1]],
              active: [7, 4, 1],
              archived: [0, 2, 3]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 93,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months'),
            trigger: [1, 2, 3],
            idle: [0, 2, 4],
            age: [1, 2, 3],
            risk: {
              map: [[0, 5, 0], [2, 1, 0], [1, 0, 0]],
              active: [3, 6, 0],
              archived: [0, 2, 0]
            },
            actions: {
              map: [[1, 3, 1], [1, 5, 3], [3, 0, 0]],
              active: [5, 8, 4],
              archived: [2, 1, 6]
            },
            issues: {
              map: [[0, 2, 2], [0, 3, 2], [1, 0, 0]],
              active: [1, 5, 4],
              archived: [1, 0, 2]
            },
            decisions: {
              map: [[2, 1, 0], [4, 2, 0], [0, 1, 0]],
              active: [6, 4, 0],
              archived: [0, 2, 5]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 65,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months'),
            trigger: [1, 1, 4],
            idle: [3, 0, 4],
            age: [1, 1, 4],
            risk: {
              map: [[0, 1, 0], [0, 1, 2], [1, 0, 0]],
              active: [1, 2, 2],
              archived: [0, 2, 4]
            },
            actions: {
              map: [[0, 2, 4], [2, 4, 4], [0, 2, 1]],
              active: [2, 8, 9],
              archived: [2, 2, 8]
            },
            issues: {
              map: [[0, 4, 3], [0, 3, 0], [0, 0, 1]],
              active: [0, 7, 4],
              archived: [2, 0, 2]
            },
            decisions: {
              map: [[0, 2, 1], [3, 2, 0], [1, 1, 0]],
              active: [4, 5, 1],
              archived: [0, 0, 9]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 50,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months'),
            trigger: [1, 3, 3],
            idle: [0, 2, 4],
            age: [1, 2, 3],
            risk: {
              map: [[0, 0, 0], [0, 1, 2], [1, 0, 0]],
              active: [1, 1, 2],
              archived: [1, 2, 4]
            },
            actions: {
              map: [[0, 2, 1], [0, 5, 4], [0, 2, 1]],
              active: [0, 9, 7],
              archived: [3, 2, 10]
            },
            issues: {
              map: [[0, 6, 1], [1, 1, 1], [4, 2, 2]],
              active: [5, 9, 4],
              archived: [2, 0, 2]
            },
            decisions: {
              map: [[0, 1, 2], [3, 3, 0], [0, 2, 0]],
              active: [3, 3, 2],
              archived: [0, 1, 10]
            }
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment(),
            trigger: [1, 2, 0],
            idle: [1, 2, 0],
            age: [2, 3, 0],
            risk: {
              map: [[0, 1, 0], [0, 0, 3], [0, 0, 0]],
              active: [0, 1, 3],
              archived: [1, 2, 6]
            },
            actions: {
              map: [[1, 1, 2], [0, 3, 5], [1, 0, 3]],
              active: [2, 4, 7],
              archived: [3, 2, 13]
            },
            issues: {
              map: [[0, 3, 3], [0, 5, 0], [1, 7, 0]],
              active: [1, 15, 3],
              archived: [3, 0, 3]
            },
            decisions: {
              map: [[0, 1, 3], [0, 3, 0], [0, 0, 0]],
              active: [4, 0, 2],
              archived: [0, 1, 13]
            }
          }
        ]
      },
      procurement: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 0,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 20,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      stakeholder: {
        unit: 'percent',
        slices: [
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(11, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(10, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(9, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(8, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 50,
            valueForecast: null,
            date: moment().set('date', 1).subtract(7, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(6, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(5, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(4, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(3, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment().set('date', 1).subtract(2, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 75,
            valueForecast: null,
            date: moment().set('date', 1).subtract(1, 'months')
          },
          {
            valueBaseLine: null,
            valueActual: 100,
            valueForecast: null,
            date: moment()
          }
        ]
      },
      comments: {
        slices: [
          {
            value: ''
          },
          {
            value: 'Off to a slow start (onboarding)'
          },
          {
            value: 'Onboarding Complete'
          },
          {
            value: 'Workshops taking longer than planned'
          },
          {
            value: 'Workshops taking longer than planned'
          },
          {
            value: 'Overtime needed for testing to catch up'
          },
          {
            value: 'Massive overtime to up velocity (higher costs/resource burnout)'
          },
          {
            value: 'Overtime + rushed testing is lowering outcome quality'
          },
          {
            value: 'Many tests have been de-scoped to make up time'
          },
          {
            value: 'Running ORT and simulations (OT and risky)'
          },
          {
            value: 'Failed cutover - added a re-do'
          },
          {
            value: 'Project end.  5% scope shed to protect schedule'
          }
        ]
      }
    }
  },
  {
    name: 'Project 2',
    id:2,
    favorite:false,
    description: 'Place holder project',
    health: 85,
  },
  {
    name: 'Overtime',
    id:3,
    favorite:true,
    description: 'Over budget project',
    health: 45,
    trend:'_'
  },
  {
    name: 'Order Flow Upgrade',
    id:4,
    favorite:true,
    description: 'enhance order UI experience for users',
    health: 75
  }
];

export default projects;
