import React, { Component } from 'react';
import { Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';

const data = [
  {
    name: 'Asana',
    key: 'asana',
    isConnected: true,
    modified: moment().subtract(2, 'days'),
    url: 'https://asana',
    status:'Incomplete'
  },
  {
    name: 'Airtable',
    key: 'airtable',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://airtable'
  },
  {
    name: 'Basecamp',
    key: 'basecamp',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://basecamp'
  },
  {
    name: 'Microsoft Teams',
    key: 'microsoft',
    isConnected: true,
    modified: moment().subtract(5, 'days'),
    url: 'https://microsoft',
    status:'Disconnected'
  },
  {
    name: 'Jira Software',
    key: 'jira',
    isConnected: true,
    modified: moment().subtract(1, 'days'),
    url: 'https://jira',
    status:'Connected'
  },
  {
    name: 'Slack',
    key: 'slack',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://asana'
  },
  {
    name: 'Axosoft',
    key: 'axosoft',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://axosoft'
  },
  {
    name: 'Monday',
    key: 'monday',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://monday'
  },
  {
    name: 'Wrike',
    key: 'wrike',
    isConnected: false,
    modified: moment().subtract(0, 'days'),
    url: 'https://wrike'
  }
];

const getStatus = (status)=>{
  switch (status) {
    case 'Disconnected':
      return 'discon'
    case 'Incomplete':
      return 'incomplete'
    default:
      return 'con';
  }
}

class AdminServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [...data],
      isEdit: {}
    };
  }
  render() {
    const { isEdit, apps } = this.state;
    const conectedApps = (apps || []).filter(a => a.isConnected === true);
    const toggleEdit = app => this.setState({ isEdit: app });
    const toggleConnect = (app, value) => {
      app.isConnected = value;
      this.setState({ apps: apps, isEdit: {} });
    };
    return (
      <Layout route="admin-services" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin`} className="back">Back</Link>
              <h1>Connectors</h1>
            </div>

            <section>
              <Tabs
                defaultActiveKey="connected"
                onSelect={() => toggleEdit({})}
              >
                <Tab eventKey="connected" title="Connected">
                  <div>
                    {!isEdit.isConnected &&
                      (conectedApps || []).map((a, index) => (
                        <div key={index} className={`connected ${a.key}`}>
                          <div className={`app ${a.key}`} />
                          <span className="edit" onClick={() => toggleEdit(a)}>
                            Edit
                          </span>
                          <strong>{a.name}</strong>
                          <div>
                            <div>
                              <b>Status</b>
                              <span className={`${getStatus((a || {}).status)}`}>{(a || {}).status || 'Connected'}</span>
                            </div>
                            <div>
                              <b>Modified</b>
                              <span>
                                {moment(a.modified).format('MMMM d, YYYY')}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    {isEdit.isConnected &&
                      <div className="is-edit">
                        <div className="cancel" onClick={() => toggleEdit({})}>
                          Cancel
                        </div>
                        <div className={`app ${isEdit.key}`} />
                        <Form.Control type="text" value={isEdit.name} />
                        <Form.Control type="text" value={isEdit.url} />
                        <div className="actions">
                          <button
                            type="button"
                            className="btn btn-delete"
                            onClick={() => toggleConnect(isEdit, false)}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="btn btn-update"
                            onClick={() => toggleEdit({})}
                          >
                            Update
                          </button>
                        </div>
                      </div>}
                  </div>
                </Tab>
                <Tab eventKey="sources" title="Sources">
                  <div>
                    {!isEdit.name &&
                      <div>
                        <h3>Select a service to integeate</h3>
                        <div className="apps">
                          {(data || [])
                            .map((a, index) => (
                              <div
                                key={index}
                                className={`app ${a.key}`}
                                onClick={() => toggleEdit(a)}
                              />
                            ))}
                        </div>
                      </div>}
                    {isEdit.name &&
                      <div className="is-edit">
                        <div className="cancel" onClick={() => toggleEdit({})}>
                          Cancel
                        </div>
                        <div className={`app ${isEdit.key}`} />
                        <Form.Control type="text" value={isEdit.name} />
                        <Form.Control type="text" value={isEdit.url} />
                        <div className="actions">
                          <button
                            type="button"
                            className="btn btn-update"
                            onClick={() => toggleConnect(isEdit, true)}
                          >
                            Create Integration
                          </button>
                        </div>
                      </div>}
                  </div>
                </Tab>
              </Tabs>
            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AdminServices;
