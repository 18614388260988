import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../ui/Layout';
import ResetYourPasswordForm from './ResetYourPasswordForm';

export const ResetYourPassword = ({ user, error, location }) => {
  let history = useHistory();
  const handleSubmit = values => {
    history.push('/reset-your-password-success');
  };
  return (
    <Layout route="reset-password">
      <Row className="justify-content-sm-center">
        <Col sm>
          <div className="logo">Crow's Nest™ App</div>
          <h1>Crow's Nest™ App</h1>
          {error && <Alert variant="warning">{error.message}</Alert>}
          <ResetYourPasswordForm onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const { result: user } = state.login.toJS();
  return { user };
};

export default connect(mapState)(ResetYourPassword);
