import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/demo-dux';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const Notifications = ({ notifications, removeNotification, user }) => {
  
  const isTier2 = (user.groups || []).includes('tier-2');

  const filteredNotifications = (notifications || []).filter(({tier})=>{
    return isTier2 ? tier === 2 : tier === 1
  })

  return (
    <Layout route="notifications" isAuthorized>
      <Row>
        <Col md={12}>
          <h1>Notifications</h1>
          <section>
            {(filteredNotifications || []).map((n, index) => (
              <div key={index} className="message">
                <div className="date">{moment(n.date).format('MMM D')}</div>
                <strong>{n.name}</strong>
                <div className="description">{n.description}</div>
                <div className="actions">
                  <Link
                    className="btn btn-primary"
                    to={`/projects/${n.project}`}
                  >
                    Review Now
                  </Link>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => removeNotification(n.id)}
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            ))}
          </section>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  notifications: state.demo.toJS().notifications,
  user: state.login.toJS().result
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(Notifications);
