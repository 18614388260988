import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import DeleteUserModal from './DeleteUserModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreatorsModal } from '../../../state/modals-dux';

const data = [
  {
    uid: 1,
    firstName: 'Alex',
    lastName: 'Strebeck',
    email: 'alex.strebeck@redcross.com',
    role: 'viewer'
  },
  {
    uid: 2,
    firstName: 'Matej',
    lastName: 'Janovcik',
    email: 'matej.janovcik@redcross.com',
    role: 'editor'
  },
  {
    uid: 3,
    firstName: 'Ben',
    lastName: 'Bluerock',
    email: 'ben.bluerock@redcross.com',
    role: 'admin'
  },
  {
    uid: 4,
    firstName: 'Ben',
    lastName: 'Redstone',
    email: 'ben.redstone@redcross.com',
    role: 'admin'
  }
];

class AdminUserDetail extends Component {
  render() {
    const { match, projects, showModal } = this.props;
    const { params } = match || {};
    const { uid } = params || {};
    const user = (data || []).find(u => u.uid == uid);
    return (
      <Layout route="admin-users" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin`} className="back">Back</Link>
              <h1>Users</h1>
            </div>

            <section>
              <div className="user-detail">
                <span>{user.firstName[0]}{user.lastName[0]}</span>
                <div>
                  <strong>{user.firstName} {user.lastName}</strong>
                  <div>{user.email}</div>
                </div>
                <div className={`role ${user.role}`}>{user.role}</div>
              </div>

              <ul className="user-actions">
                <li>
                  <Link to={`/admin/users/${user.uid}/password`}>
                    Password Reset
                  </Link>
                </li>
                <li>
                  <Link to={`/admin/users/${user.uid}/roles`}>
                    User Roles
                  </Link>
                </li>
                {/*
                <li>
                  <Link to="#">
                    Status
                  </Link>
                </li>
                */}
                <li>
                  <a href="#" onClick={() => showModal('DeleteUserModal')}>
                    Delete User
                  </a>
                </li>
              </ul>

              <div className="projects">
                <h3>Projects</h3>
                <div className="project">
                  <div>
                    <strong>Project Name 1</strong>
                    <span className="health orange">78/100</span>
                  </div>
                  <div>
                    <small>Subtitle/short description</small>
                    <span className="trend">
                      Trend <span className="up" />
                    </span>
                  </div>
                </div>
                <div className="project">
                  <div>
                    <strong>Project Name 2</strong>
                    <span className="health green">92/100</span>
                  </div>
                  <div>
                    <small>Subtitle/short description</small>
                    <span className="trend">
                      Trend <span className="up" />
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <DeleteUserModal />

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreatorsModal
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AdminUserDetail);
