import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/demo-dux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../ui/Layout';
import LoginForm from './LoginForm';

export const Login = ({ user, error, location, postLogin, setMyProjects }) => {
  const isAuthorized = authorized({
    user
  });
  if (isAuthorized) return <Redirect to={location.from || { pathname: '/' }} />;
  const handleLogin = values =>
    postLogin(values, 'login', null, data => setMyProjects(data));
  return (
    <Layout route="login">
      <Row className="justify-content-sm-center">
        <Col sm>
          <div className="logo">Crow's Nest™ App</div>
          <h1>Crow's Nest™ App</h1>
          {error && <Alert variant="warning">{error.message}</Alert>}
          <LoginForm onSubmit={handleLogin} />
          <p>
            Don't have an account? <Link to="/sign-up">Sign up</Link>.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Login);
