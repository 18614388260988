import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';
const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Enter a vaild email address'
    : undefined;

const SignUpForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <h2>Sign up</h2>
      <p className="help">What's your work email?</p>
      <Field
        name="username"
        type="text"
        component={FormField.Input}
        label="Email"
        validate={[required, validEmail]}
        insetLabel
        icon="envelope"
      />
      <div>
        <Button className="btn btn-primary" type="submit">
          Sign up
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'sign-up'
})(SignUpForm);
