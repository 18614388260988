import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreatorsModal } from '../../../state/modals-dux';

const RemoveProjectModal = (
  { openModal, closeModal, id, toggleProjectFavorite }
) => {
  const deleteProject = () => {
    toggleProjectFavorite({ id: id, value: false });
    closeModal();
  };
  return (
    <Modal
      show={openModal === 'RemoveProjectModal'}
      className="modal-confirmation"
      onHide={closeModal}
    >
      <Modal.Body>
        <h4>Are you sure you want remove this project?</h4>
        <p>If you remove, you may lose access to this project.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteProject}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(actionCreatorsModal, dispatch);

export default connect(mapState, mapDispatch)(RemoveProjectModal);
