import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../ui/Layout';
import UpdatePasswordForm from './UpdatePasswordForm';

export const UpdatePassword = ({ user, error, location, postLogin }) => {
  return (
    <Layout route="update-password">
      <Row className="justify-content-sm-center">
        <Col sm>
          <div className="logo">Crow's Nest™ App</div>
          <h1>Crow's Nest™ App</h1>
          {error && <Alert variant="warning">{error.message}</Alert>}
          <UpdatePasswordForm onSubmit={values => postLogin(values)} />
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UpdatePassword);
