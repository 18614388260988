import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export const ProjectHealth = ({ match, projects }) => {
  const { params } = match || {};
  const { id } = params || {};
  const project = (projects || []).find(p => p.id == id);

  const { innerWidth: width, innerHeight: height } = window;

  return (
    <Layout route="project-health" isAuthorized>
      <Row>
        <Col md={12}>

          <section style={{ paddingBottom: '55px' }}>

            <div className="header">
              <Link to={`/projects/${id}/schedule`} />
              <h1>Roadmap</h1>
            </div>
            <div>
              <img
                src="/Roadmap.png"
                style={{
                  maxWidth: 'calc(100% - 2rem)',
                  height: 'auto',
                  margin: '1rem',
                  dispaly: 'block'
                }}
              />
            </div>
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectHealth);
