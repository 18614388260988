import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

const data = [
  {
    date: moment().subtract(1, 'days'),
    name: 'Erin Obernesser',
    avatar: 'EO',
    action: 'Added User'
  },
  {
    date: moment().subtract(1573, 'minutes'),
    name: 'Joe Obernesser',
    avatar: 'JO',
    action: 'Added User'
  }
];

const LogsSearchForm = props => {
  const { handleSubmit, search } = props;
  const results = search
    ? (data || [])
        .filter(p => p.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    : data;
  return (
    <div>

      <form onSubmit={handleSubmit}>
        <Field
          name="search"
          type="text"
          component={FormField.Input}
          label="Search"
          insetLabel
          icon="search"
        />
      </form>

      <div className="logs-list">
        {(results || []).map((i, index) => (
          <div className="log" key={index}>
            <strong>{moment(i.date).format('lll')}</strong>
            <div>
              <span>{i.avatar}</span>
              <div>
                <strong>{i.name}</strong>
                <div>{i.action}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

const formName = 'logs-search-form';
const selector = formValueSelector(formName);

const mapState = state => {
  const search = selector(state, 'search');
  return {
    search
  };
};

const form = reduxForm({
  form: formName
})(LogsSearchForm);

export default connect(mapState)(form);
