import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TrendChart from '../../../highcharts/TrendChartV2';
import numeral from 'numeral';
import colorGradient from '../../../highcharts/gradients';

const getDetailText = score => {
  switch (score) {
    case 'scope':
      return 'Project Scope Management is a process that helps in determining and documenting the list of all the project goals, tasks, deliverables, deadlines, and budgets as a part of the planning process.';
    case 'schedule':
      return 'Project Time Management is an act of exercising or planning the conscious control on the time spent on specific tasks or activities. Project Schedule Management involves practices to guarantee timely completion of the project. ';
    case 'cost':
      return 'Project Cost Management is the process of estimating, budgeting and controlling costs throughout the project life cycle, with the objective of keeping expenditures within the approved budget. its completed within budget.';
    case 'quality':
      return 'Project Quality Management is the process of continually measuring the quality of all activities and taking corrective action until the desired quality is achieved.';
    case 'resources':
      return 'Project Resource Management refers to a set of integrated processes that enable project managers (possibly in conjunction with the PMO and other decision-makers) to determine, obtain, allocate, develop, and monitor the resources that are required to successfully complete a project.';
    case 'communication':
      return 'Project Communications Management ensures that key information flows efficiently and in a predetermined way between the various people working on (or impacted by) a project.';
    case 'raid':
      return 'Project Risk Management The purpose of Project Risk Management is to identify project risks and develop strategies to prevent them from occurring or minimize their impact to the project if they do occur. ';
    case 'procurement':
      return 'Project Procurement Management is the creation and maintenance of relationships with external resources needed to complete a project. ';
    case 'stakeholder':
      return 'Project Stakeholder Management is a critical component to the successful delivery of any project, program or activity. A stakeholder is any individual, group or organization that can affect, be affected by, or perceive itself to be affected by a program.';
  }
};

const getScoreHealthDetail = valueActualPercent => {
  if (85 <= valueActualPercent) {
    return 'score shows project as being "Healthy." This indicates positive performance trends across multiple dimensions of the project.';
  }
  if (70 <= valueActualPercent) {
    return 'score shows project as being "At Risk." This indicates several key performance metrics are facing challenges that require corrective action to return to a "Healthy" project state.';
  }
  return 'score shows project as being "Critical State" where action is required. This indicates escalation to executive key stakeholders to address critical blockers and setbacks as required.';
};

export const ProjectHealthScore = ({ match, projects }) => {
  const { id, score } = match.params || {};

  const project = (projects || []).find(p => p.id == id);
  const {
    nowSliceIndexTier1,
    projectLead,
    tier1
  } = project || {};

  const {
    slices
  } = tier1[score] || {};

  let {
    valueActual,
    valueActualPercent
  } = slices[nowSliceIndexTier1] || {};

  if (score === 'cost') {
    valueActual = valueActualPercent;
  }

  return (
    <Layout route="project-health-score" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/projects/${id}/health`} className="back">Back</Link>
            <h1>Score Detail</h1>
          </div>

          <section>

            <div className="summary">
              <h2>{score}</h2>
              <div
                style={{
                  color: colorGradient(
                    (valueActual > 100 ? 100 : valueActual) / 100
                  )
                }}
              >
                {valueActual}
              </div>
            </div>

            <div className="detail">
              <h3>{score} CURRENT SCORE DETAIL</h3>
              <p>
                {getDetailText(score)}
              </p>
            </div>

            <div className="trend">
              <h3>Trend</h3>
              <TrendChart
                projectData={project}
                asPercent={true}
                height={'100px'}
                width={'100%'}
                containerName={'health-score-chart'}
                backgroundColor={'transparent'}
                knowledgeArea={score}
                isGradient
                hideLegend
                hideXAxis
                hideYAxis
                showDatalabel
              />
              <p>
                <span style={{ textTransform: 'capitalize' }}>{score}</span>
                {' '}
                {getScoreHealthDetail(valueActual)}
              </p>
            </div>

            <div className="project-lead">
              <span>{projectLead}</span>
              <h3>Project Lead</h3>
              <div className="actions">
                <button className="btn email">
                  Email
                </button>
                <button className="btn phone">
                  Phone
                </button>
                <button className="btn message">
                  Message
                </button>
              </div>
            </div>

          </section>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectHealthScore);
