import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import numeral from 'numeral';
import ProjectNav from './ProjectNav';

const data = {
  spend: {
    amount: 350000,
    tx: 7,
    suppliers: 4
  },
  suppliers: {
    total: 9,
    it: 4,
    hr: 1,
    consulting: 2,
    services: 2
  },
  transactions: [
    {
      active: true,
      name: 'LVMH Accociates',
      type: 'Agency',
      spend: 45000,
      contact: 'Ashlee Collins'
    },
    {
      active: true,
      name: 'IBM',
      type: 'Technology',
      spend: 55000,
      contact: 'Ashlee Collins'
    },
    {
      active: true,
      name: 'Deloitte',
      type: 'Consulting',
      spend: 100000,
      contact: 'Ashlee Collins'
    },
    {
      active: true,
      name: 'Cisco',
      type: 'Technology',
      spend: 50000,
      contact: 'Ashlee Collins'
    },
    {
      active: false,
      name: 'Salesforce',
      type: 'Technology',
      spend: 100000,
      contact: 'Ashlee Collins'
    }
  ],
  notice: [
    { name: '90 day notice', description: 'LVHM Contract ends 03/30/21' },
    { name: '30 day notice', description: 'Amazon RFP due 03/30/21' }
  ]
};

class ProjectDetailsProcurement extends Component {
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      tier2
    } = project || {};

    const {
      cost
    } = tier2 || {};

    const {
      valueActualCost
    } = cost.slices[activeSliceIndex] || {};

    const setSliceIndex = value => setActiveSlice(value);
    return (
      <Layout route="project-details-t2 procurement" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="procurement"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <Row>
                <Col>

                  <div className="suppliers bg">
                    <h3>Suppliers</h3>
                    <strong>{data.suppliers.total}</strong>
                    <div>
                      <span>{data.suppliers.it}</span> IT
                    </div>
                    <div>
                      <span>{data.suppliers.hr}</span> HR
                    </div>
                    <div>
                      <span>{data.suppliers.consulting}</span> Consulting
                    </div>
                    <div>
                      <span>{data.suppliers.services}</span> Prof Services
                    </div>
                  </div>

                </Col>
                <Col>

                  <div className="spend bg">
                    <h3>Spend</h3>
                    <strong>{numeral(valueActualCost/4).format('$0.000a')}</strong>
                    <p>
                      Across
                      {' '}
                      {data.spend.tx}
                      {' '}
                      transactions and
                      {' '}
                      {data.spend.suppliers}
                      {' '}
                      suppliers
                    </p>
                  </div>

                </Col>
              </Row>

              <Row className="filters">
                <Col>

                  <button className="btn" onClick={() => {}}>
                    Filters
                  </button>

                </Col>
                <Col>

                  <button className="btn" onClick={() => {}}>
                    Sort
                  </button>

                </Col>
              </Row>

              <div className="list bg">
                {(data.transactions || []).map((t, index) => (
                  <div key={index} className="transaction">
                    <span className={`status ${t.active ? 'active' : ''}`}>
                      {t.active ? `Active` : `Inactive`}
                    </span>
                    <strong>{t.name}</strong>
                    <div><span>Type</span> {t.type}</div>
                    <div>
                      <span>Spend</span> {numeral(t.spend).format('$0,000')}
                    </div>
                    <div><span>Contact</span> {t.contact}</div>
                  </div>
                ))}
              </div>

              <div className="notice bg">
                {(data.notice || []).map((t, index) => (
                  <div key={index}>
                    <strong>{t.name}</strong>
                    <span>{t.description}</span>
                  </div>
                ))}
              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsProcurement);
