import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // https://react-slick.neostack.com/docs/api
import ReactSlider from 'react-slider'; // https://zillow.github.io/react-slider/
import colorGradient from '../../../highcharts/gradients';
import seriesLoaderArea from '../../../highcharts/seriesLoaderArea';
import AreaChart from '../../../highcharts/AreaChart';
import moment from 'moment';
import numeral from 'numeral';

const mapRange = require('../../../highcharts/utils/mapRange').handler;

const getSliderGradient = (project, category) => {
  const seriesForecast = seriesLoaderArea({
    projectData: project,
    knowledgeArea: category === 'risk' ? 'raid' : category,
    valueType: 'valueForecast',
    asPercent: true,
    maxPercentValue: project.totalBudget,
    isGradient: true
  });

  const seriesActual = seriesLoaderArea({
    projectData: project,
    knowledgeArea: category === 'risk' ? 'raid' : category,
    valueType: 'valueActual',
    asPercent: true,
    maxPercentValue: project.totalBudget,
    isGradient: true
  });

  const forecastGradientStops = seriesForecast.color.stops || {};
  const actualGradientStops = seriesActual.color.stops || {};

  let cssGradientStops = [];

  forecastGradientStops.forEach(stop => {
    cssGradientStops.push(stop[1] + ' ' + numeral(stop[0]).format('0.00%'));
  });

  actualGradientStops.forEach(stop => {
    cssGradientStops.push(stop[1] + ' ' + numeral(stop[0]).format('0.00%'));
  });

  //  linear-gradient(64.25deg, #5AC53A 0%, #72C842 0.01%, #EDD86D 51.18%, #ED6D6D 100%)

  return 'linear-gradient(90deg, ' + cssGradientStops.join(', ') + ')';
};

const nav = [
  { label: 'Overall', key: 'overall' },
  { label: 'Cost', key: 'cost' },
  { label: 'Risk', key: 'risk' },
  { label: 'Procurement', key: 'procurement' },
  { label: 'Schedule', key: 'schedule' },
  { label: 'Scope', key: 'scope' },
  { label: 'Quality', key: 'quality', disabled: true },
  { label: 'Resources', key: 'resources', disabled: true },
  { label: 'Communication', key: 'communication', disabled: true },
  { label: 'Stakeholder', key: 'stakeholder', disabled: true }
];

class ProjectNav extends Component {
  render() {
    const { project, category, handleChange, currentSlice } = this.props;
    const { innerWidth: width } = window;
    const {
      id,
      endSliceIndex,
      tier2
    } = project || {};
    const settings = {
      dots: false,
      infinite: width < 600,
      speed: 500,
      slidesToShow: 3,
      arrows: false,
      variableWidth: true,
      adaptiveHeight: false,
      centerMode: true,
      initialSlide: nav.findIndex(n => n.key === category)
    };

    let nowSliceValue = null;
    let nowSliceDate = null;
    if (category === 'risk') {
      nowSliceValue = tier2.raid.slices[currentSlice].valueActual;
      nowSliceDate = tier2.raid.slices[currentSlice].date;
    } else if (category === 'cost') {
      nowSliceValue = tier2[category].slices[currentSlice].valueActualPercent;
      nowSliceDate = tier2[category].slices[currentSlice].date;
    } else {
      nowSliceValue = tier2[category].slices[currentSlice].valueActual;
      nowSliceDate = tier2[category].slices[currentSlice].date;
    }

    return (
      <div className="project-navigation">
        <Slider {...settings}>
          {(nav || []).map((n, index) => {
            let value = null;
            if (n.key === 'risk') {
              value = tier2.raid.slices[currentSlice].valueActual;
            } else if (n.key === 'cost') {
              value = tier2.cost.slices[currentSlice].valueActualPercent;
            } else {
              value = tier2[n.key].slices[currentSlice].valueActual;
            }
            return (
              <div key={index}>
                {n.disabled &&
                  <div className="disabled">
                    {n.label} <span />
                  </div>}
                {!n.disabled &&
                  <Link
                    to={`/projects/${id}/${n.key}`}
                    className={category === n.key ? 'active' : ''}
                  >
                    {n.label}
                    {' '}
                    <span
                      style={{
                        backgroundColor: colorGradient(mapRange(value))
                      }}
                    />
                  </Link>}
              </div>
            );
          })}
        </Slider>
        <div className="timeline">
          <AreaChart
            projectData={project}
            asPercent={true}
            height={'150px'}
            width={'100%'}
            containerName={'health-projection-detail'}
            backgroundColor={'transparent'}
            knowledgeArea={category === 'risk' ? 'raid' : category}
            isGradient
            hideLegend
            hideXAxis
            hideYAxis
          />

          <div className="slider">
            <ReactSlider
              className="horizontal-slider"
              marks
              markClassName="timeline-mark"
              min={0}
              max={endSliceIndex}
              thumbClassName="timeline-thumb"
              trackClassName="timeline-track"
              defaultValue={currentSlice}
              renderTrack={(props, state) => (
                <div
                  {...props}
                  style={{ background: getSliderGradient(project, category) }}
                />
              )}
              renderThumb={(props, state) => (
                <div {...props}>
                  <div {...props} className="overlay">
                    <strong
                      style={{ color: colorGradient(mapRange(nowSliceValue)) }}
                    >
                      {nowSliceValue || 0}
                    </strong>
                    <span>{moment(nowSliceDate).format('MMM YYYY')}</span>
                  </div>
                </div>
              )}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectNav;
