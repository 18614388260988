import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

export const AdminBilling = () => {
  return (
    <Layout route="admin-billing" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/admin`} className="back">Back</Link>
            <h1>Billing</h1>
          </div>

          <section />

        </Col>
      </Row>
    </Layout>
  );
};

export default AdminBilling;
