import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

export const ProjectSettings = ({ match, projects }) => {
  const { params } = match || {};
  const { id } = params || {};
  const project = (projects || []).find(p => p.id == id);
  const {
    name,
    description,
    projectDescription,
    startDate,
    endDate
  } = project || {};
  return (
    <Layout route="project-settings">
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/projects/${id}`} className="back">Back</Link>
            <h1>Project Settings</h1>
          </div>

          <section>
            <h2>Details</h2>
            <div className="group">
              <span>Edit</span>
              <h3>Project Name</h3>
              <div>{name}</div>
            </div>
            <div className="group">
              <span>Edit</span>
              <h3>Short Description</h3>
              <div>{description}</div>
            </div>
            <div className="group">
              <span>Edit</span>
              <h3>Project Details</h3>
              <div>{projectDescription}</div>
            </div>
            <div className="group">
              <span>Edit</span>
              <h3>Published Timeslices</h3>
              <div>
                <Row>
                  <Col>
                    <div className="date">
                      {moment(startDate).format('MMM d, YYYY')}
                    </div>
                  </Col>
                  <Col>
                    <div className="date">
                      {moment(endDate).format('MMM d, YYYY')}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectSettings);
