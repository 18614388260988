import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../ui/Layout';
import SignUpForm from './SignUpForm';

export const SignUp = ({ user, error, location }) => {
  const isAuthorized = authorized({ user });
  let history = useHistory();
  if (isAuthorized) return <Redirect to={location.from || { pathname: '/' }} />;
  const handleSubmit = values => {
    history.push('/sign-up-success');
  };
  return (
    <Layout route="sign-up">
      <Row className="justify-content-sm-center">
        <Col sm>
          <div className="logo">Crow's Nest™ App</div>
          <h1>Crow's Nest™ App</h1>
          {error && <Alert variant="warning">{error.message}</Alert>}
          <SignUpForm onSubmit={handleSubmit} />
          <p>
            Already use Crow's Nest? <Link to="/login">Log in</Link>.
          </p>
          <div className="terms">
            By signing up you accept the Terms of Service and Privacy Policy.
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const { result: user } = state.login.toJS();
  return { user };
};

export default connect(mapState)(SignUp);
