import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const LoginForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <h2>Log in</h2>
      <Field
        name="username"
        type="text"
        component={FormField.Input}
        label="Email"
        validate={[required]}
        insetLabel
        icon="envelope"
      />
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        label="Password"
        validate={[required]}
        icon="key"
        insetLabel
      />
      <Link className="link" to="/reset-your-password">Forgot Password?</Link>
      <div>
        <Button variant="primary" type="submit" disabled={submitting}>
          Log in
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'login'
})(LoginForm);
