import { actionNamespace } from './defaults';

const SHOW_MODAL = `${actionNamespace}/SHOW_MODAL`;
const CLOSE_MODAL = `${actionNamespace}/CLOSE_MODAL`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return payload;
    case CLOSE_MODAL:
      return defaultState;
    default:
      return state;
  }
};

export const actionCreatorsModal = {
  showModal: payload => ({ type: SHOW_MODAL, payload }),
  closeModal: () => ({ type: CLOSE_MODAL })
};
