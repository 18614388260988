const mapRange = require('./mapRange').handler

const colorGradient = (fadeFraction, rgbColor1, rgbColor2, rgbColor3) => {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10)
  };

  return 'rgb(' +
    gradient.red +
    ',' +
    gradient.green +
    ',' +
    gradient.blue +
    ')';
};


const calcColor = ({dataPoint, currentStop}) => {
  
  const max = 95;
  const min = 70;

  return [
    currentStop
    ,
    colorGradient(
      mapRange(dataPoint, min, max, 0, 100),
    { red: 223, green: 83, blue: 83 }, // red '#DF5353'
    { red: 221, green: 223, blue: 13 }, // yellow '#DDDF0D'
    { red: 85, green: 191, blue: 59 } // green '#55BF3B'
    )
  ]

};

const getHorizontalStops = ({dataPoints})=>{

  let currentStop = 0;
  const stopsNumber = (dataPoints || []).filter(element => element.y !== null).length;
  const stopChange = 1 / (stopsNumber-1);
  return (dataPoints || []).filter(element => element.y !== null).map(({y})=>{

    const color = calcColor({dataPoint:y, currentStop});
    currentStop += stopChange;
    return color
  })

};

const calcIntermediateStops = (stops) =>{

  const newStops = [];
  
  (stops || []).reduce((prev, next, index)=>{

    const [ _prev1, _prev2, valuePrev] = prev;
    const [ _next1, _next2, valueNext] = next;

    if(valueNext < 73 && valuePrev > 82){
      newStops.push([calcColor({dataPoint:80, currentStop:(_prev1+(_next1 -_prev1)/2)}), index + (newStops || []).length]);
      return next;
    }
    else if(valuePrev < 73 && valueNext > 82){
      newStops.push([calcColor({dataPoint:80, currentStop:(_prev1+(_next1 -_prev1)/2)}), index + (newStops || []).length]);
      return next;
    }
    else{
      return next
    }

  }, []);

  (newStops || []).map(([color, arrIndex])=>(stops || []).splice(arrIndex, 0, color));

  return stops;

}

export default ({ dataPoints }) => {
    const stops = getHorizontalStops({dataPoints});
    calcIntermediateStops(stops);

    return {
      // x1 to x2 determines horizontal gradient
      linearGradient: {
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 0
      },
      stops
    };
};
