import React, { Component } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import numeral from 'numeral';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/demo-dux';
import seriesLoader from './seriesLoader';
// import ReactHighcharts from 'react-highcharts';
import HighchartsReact from 'highcharts-react-official';

/* used for the cost and budget charts*/

class BudgetChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: null
    };
  }

  highChartsRender = (
    {
      seriesInfo,
      yAxisSide,
      dataMax,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      height,
      width,
      backgroundColor,
      isTier2
    }
  ) => {
    const config = {
      chart: {
        height,
        width,
        type: 'spline',
        renderTo: containerName,
        spacingBottom: 0,
        spacingTop: 20,
        spacingLeft: 0,
        spacingRight: 0,
        marginRight: 35,
        backgroundColor: backgroundColor || '#FFFFFF',
        animation: false
      },
      title: {
        verticalAlign: '',
        floating: true,
        text: '',
        style: {
          fontSize: '10px'
        }
      },
      yAxis: {
        opposite: yAxisSide,
        visible: !hideYAxis,
        title: {
          enabled: false
        },
        max: isTier2 ? dataMax : dataMax + 0.5,
        lineColor: '#DDDDDD',
        lineWidth: 1,
        gridLineColor: 'transparent',
        labels: {
          x: 5,
          formatter: function() {
            return asPercent
              ? this.axis.defaultLabelFormatter.call(this) + '%'
              : '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      xAxis: {
        offset: 10,
        type: 'datetime',
        visible: !hideXAxis,
        crosshair: true,
        tickLength: 0,
        dateTimeLabelFormats: {
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b', //month formatted as month only
          year: '%Y'
        }
      },
      legend: {
        enabled: hideLegend
      },
      tooltip: {
        useHTML: true,
        shared: true,
        legend: { enabled: false },
        borderWidth: 0,
        backgroundColor: 'transparent',
        padding: 0,
        shadow: false,
        positioner: function(labelWidth, labelHeight, point) {
          var position = point.plotX - labelWidth / 2;
          if (point.plotX < labelWidth / 2) {
            position = 2;
          } else if (point.plotX > 310 - labelWidth / 2) {
            position = point.plotX - (280 - labelWidth - 2);
          }
          return { x: position, y: 0 };
        },
        formatter: function() {
          let values = [];
          values.push(
            '<div class="date">' +
              moment(this.points[0].x).format('MMM D, YYYY') +
              '</div>'
          );
          this.points.forEach((point, i) => {
            values.push(
              '<span class="values"><b>' +
                numeral(asPercent ? point.y / 100 : point.y).format(
                  asPercent ? '0%' : '$0a'
                ) +
                '</b> ' +
                point.series.name +
                '</span>'
            );
          });
          if (values.length > 3) {
            values = values.filter(v => !v.includes('Forecast'));
          }
          return values.join(' ');
        }
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              enabled: true,
              halo: {
                size: 0
              }
            }
          }
        }
      },
      series: (seriesInfo || []).map(series => series)
    };

    return config;
  };

  render() {
    const {
      projectData,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      hideBudget,
      hideActual,
      hideForecast,
      width,
      height,
      backgroundColor,
      summaryTimeframe,
      isTier2
    } = this.props || {};

    const {
      totalBudget
    } = projectData || {};

    const seriesActual = seriesLoader({
      projectData,
      knowledgeArea: 'cost',
      valueType: 'valueActual',
      name: 'Actual',
      color: '#18A0FB',
      lineStyle: 'Solid',
      hideMarkers: false,
      asPercent,
      maxPercentValue: totalBudget,
      hide: hideBudget,
      marker: {
        symbol: 'circle',
        radius: 2
      },
      summaryTimeframe,
      isTier2
    }); //mainly for cost values

    const seriesBudget = seriesLoader({
      projectData,
      knowledgeArea: 'cost',
      valueType: 'valuePlanned',
      name: 'Budget',
      color: '#7B39F5',
      lineStyle: 'Solid',
      hideMarkers: false,
      asPercent,
      maxPercentValue: totalBudget,
      hide: hideActual,
      marker: {
        symbol: 'circle',
        radius: 2
      },
      summaryTimeframe,
      isTier2
    }); //mainly for cost values

    const seriesForecast = seriesLoader({
      projectData,
      knowledgeArea: 'cost',
      valueType: 'valueForecast',
      name: 'Forecast',
      color: '#18A0FB',
      lineStyle: 'Shortdash',
      hideMarkers: false,
      asPercent,
      maxPercentValue: totalBudget,
      hide: hideForecast,
      marker: {
        symbol: 'circle',
        radius: 2
      },
      summaryTimeframe,
      isTier2
    }); //mainly for cost values

    const finalMax = asPercent ? 100 : totalBudget;

    const chart = this.highChartsRender({
      seriesInfo: [seriesActual, seriesForecast, seriesBudget],
      yAxisSide: true,
      dataMax: finalMax,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      backgroundColor,
      isTier2
    });

    return (
      <div id={containerName} style={{ height, width }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
          containerProps={{ style: { width: '100%', height: '100%' } }}
        />
      </div>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(BudgetChart);
