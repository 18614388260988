import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/demo-dux';
import { actionCreatorsModal } from '../../../state/modals-dux';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import SearchForm from './SearchForm';
import RemoveProjectModal from './RemoveProjectModal';

class HomepageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { projectId: null };
  }
  render() {
    const { projects, toggleProjectFavorite, showModal } = this.props;
    const { projectId } = this.state;
    const myProjects = (projects || []).filter(p => p.favorite === true);
    const deleteProject = id => {
      this.setState({ projectId: id });
      showModal('RemoveProjectModal');
    };
    return (
      <Layout route="projects-edit" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <h1>Projects</h1>
              </div>
              <div>
                <Link to="/">Done</Link>
              </div>
            </div>

            <SearchForm
              projects={projects}
              showModal={showModal}
              deleteProject={deleteProject}
            />

            <RemoveProjectModal
              id={projectId}
              toggleProjectFavorite={toggleProjectFavorite}
            />

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      ...actionCreatorsModal
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(HomepageEdit);
