import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';
const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Enter a vaild email address'
    : undefined;

const CompleteAccountForm = props => {
  const { handleSubmit, submitting, password } = props;
  const regex = /[^\p{L}\s@#]/u;
  const isPwnAtLeast8Chars = (password || '').length >= 8 ? true : false;
  const doesContainNumOrSym = regex.test(password);
  return (
    <form onSubmit={handleSubmit}>
      <h2>Complete Account</h2>
      <Field
        name="fullName"
        type="text"
        component={FormField.Input}
        label="Full name"
        validate={[required]}
        insetLabel
        icon="user"
      />
      <Field
        name="username"
        type="text"
        component={FormField.Input}
        label="Email"
        validate={[required, validEmail]}
        insetLabel
        icon="envelope"
      />
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        label="Password"
        validate={[required]}
        icon="key"
        insetLabel
      />
      {password &&
        <div className="password-req">
          <div className={(password || '').length >= 8 ? 'valid' : ''}>
            Password strength:
            {(password || '').length < 8 &&
              <strong className="red"> weak</strong>}
            {(password || '').length >= 8 &&
              (password || '').length < 10 &&
              <strong className="orange"> medium</strong>}
            {(password || '').length >= 10 &&
              <strong className="green"> strong</strong>}
          </div>
          <div className="valid">
            Can’t contain your name or email address
          </div>
          <div className={isPwnAtLeast8Chars ? 'valid' : ''}>
            At least 8 characters
          </div>
          <div className={doesContainNumOrSym ? 'valid' : ''}>
            Contains a number or symbol
          </div>
        </div>}
      <div>
        <Button className="btn btn-primary" type="submit">
          Let's go!
        </Button>
      </div>
    </form>
  );
};

const formName = 'complete-account';
const selector = formValueSelector(formName);

const mapState = state => {
  const password = selector(state, 'password');
  return {
    password
  };
};

const form = reduxForm({
  form: formName
})(CompleteAccountForm);

export default connect(mapState)(form);
