import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreatorsModal } from '../../../state/modals-dux';

const DeleteUserModal = ({ openModal, closeModal }) => {
  return (
    <Modal
      show={openModal === 'DeleteUserModal'}
      className="modal-confirmation"
      onHide={closeModal}
    >
      <Modal.Body>
        <h4>Are you sure you want delete this user?</h4>
        <p>If you delete, user will lose access to the projects.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={closeModal}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(actionCreatorsModal, dispatch);

export default connect(mapState, mapDispatch)(DeleteUserModal);
