import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AdminDashboard from './AdminDashboard';
import AdminUsers from './AdminUsers';
import AdminAddUser from './AdminAddUser';
import AdminUserDetail from './AdminUserDetail';
import AdminUserPassword from './AdminUserPassword';
import AdminUserRoles from './AdminUserRoles';
import AdminServices from './AdminServices';
import AdminLogs from './AdminLogs';
import AdminSecurity from './AdminSecurity';
import AdminPremissions from './AdminPremissions';
import AdminBilling from './AdminBilling';

export const Admin = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={AdminDashboard} />
    <Route exact path={`${match.url}/users`} component={AdminUsers} />
    <Route
      exact
      path={`${match.url}/users/add`}
      component={AdminAddUser}
      exact
    />
    <Route exact path={`${match.url}/users/:uid`} component={AdminUserDetail} />
    <Route
      exact
      path={`${match.url}/users/:uid/password`}
      component={AdminUserPassword}
    />
    <Route
      exact
      path={`${match.url}/users/:uid/roles`}
      component={AdminUserRoles}
    />
    <Route exact path={`${match.url}/services`} component={AdminServices} />
    <Route exact path={`${match.url}/logs`} component={AdminLogs} />
    <Route exact path={`${match.url}/security`} component={AdminSecurity} />
    <Route
      exact
      path={`${match.url}/premissions`}
      component={AdminPremissions}
    />
    <Route exact path={`${match.url}/billing`} component={AdminBilling} />
    <Redirect to="/" />
  </Switch>
);

export default Admin;
