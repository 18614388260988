import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import ProjectNav from './ProjectNav';

class ProjectDetailsQuality extends Component {
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers
    } = project || {};
    const setSliceIndex = value => setActiveSlice(value);
    return (
      <Layout route="project-details-t2 quality" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="quality"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              t2 Quality

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsQuality);
