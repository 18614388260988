import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';

import SecureRoute from './ui/SecureRoute';
import Login from './routes/login/Login';
import SignUp from './routes/sign-up/SignUp';
import SignUpSuccess from './routes/sign-up/SignUpSuccess';
import CompleteAccount from './routes/complete-account/CompleteAccount';
import UpdatePassword from './routes/update-password/UpdatePassword';
import ResetYourPassword from './routes/reset-your-password/ResetYourPassword';
import ResetYourPasswordSuccess
  from './routes/reset-your-password/ResetYourPasswordSuccess';

import Homepage from './routes/homepage/Homepage';
import HomepageEdit from './routes/homepage/HomepageEdit';
import ProjectDetails from './routes/projects/ProjectDetails';
import Search from './routes/search/Search';
import Notifications from './routes/notifications/Notifications';
import Profile from './routes/profile/Profile';
import Admin from './routes/admin/Admin';

class App extends Component {
  componentDidMount() {
    this.props.validateLogin();
  }
  render() {
    return (
      <Router>
        <Switch>
          <SecureRoute path="/" component={Homepage} exact />
          <SecureRoute path="/edit" component={HomepageEdit} exact />
          <SecureRoute path="/projects" component={ProjectDetails} />
          <SecureRoute path="/search" component={Search} exact />
          <SecureRoute path="/notifications" component={Notifications} exact />
          <SecureRoute path="/profile" component={Profile} exact />
          <SecureRoute path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          <Route path="/reset-your-password" component={ResetYourPassword} />
          <Route
            path="/reset-your-password-success"
            component={ResetYourPasswordSuccess}
          />
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-up-success" component={SignUpSuccess} />
          <Route path="/complete-account" component={CompleteAccount} />
        </Switch>
      </Router>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateLogin: fetchDux.validateLogin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
