import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/demo-dux';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import classNames from 'classnames';

const getProjectHealthColor = value => {
  if (value < 60) {
    return 'red';
  }
  if (value < 80) {
    return 'orange';
  }
  return 'green';
};

const getProjectTrend = value => {
  if (value === '+') {
    return 'up';
  }
  if (value === '-') {
    return 'down';
  }
  return 'neutral';
};

class Homepage extends Component {
  render() {
    const { user, projects } = this.props;
    const myProjects = (projects || []).filter(p => p.favorite === true);
    return (
      <Layout route="homepage" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <div className="logo" />
                <h1>Crow's Nest™ App</h1>
              </div>
              <div>
                <Link to="/edit">Edit</Link>
              </div>
            </div>

            <Tabs defaultActiveKey="my">
              <Tab eventKey="my" title="My Projects">
                <div className="projects">
                  {(myProjects || []).map((project, index) => (
                    <Link
                      key={index}
                      className="project"
                      to={`/projects/${1}`} //hard coded to 1 because we do not have  more project data but want to show more projects on home screen
                    >
                      <div>
                        <strong>{project.name}</strong>
                        <span
                          className={classNames(
                            'health',
                            getProjectHealthColor(project.health)
                          )}
                        >
                          {project.health}/100
                        </span>
                      </div>
                      <div>
                        <small>{project.description}</small>
                        <span className="trend">
                          Trend
                          <span className={getProjectTrend(project.trend)} />
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              </Tab>
              <Tab eventKey="all" title="All Projects">
                <div className="projects">
                  {(projects || []).map((project, index) => (
                    <Link
                      key={index}
                      className="project"
                      to={`/projects/${1}`} //hard coded to 1 because we do not have  more project data but want to show more projects on home screen
                    >
                      <div>
                        <strong>{project.name}</strong>
                        <span
                          className={classNames(
                            'health',
                            getProjectHealthColor(project.health)
                          )}
                        >
                          {project.health}/100
                        </span>
                      </div>
                      <div>
                        <small>{project.description}</small>
                        <span className="trend">
                          Trend
                          <span className={getProjectTrend(project.trend)} />
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              </Tab>
            </Tabs>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  user: state.login.toJS().result,
  projects: state.demo.toJS().projects
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(Homepage);
