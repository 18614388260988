import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import LogsSearchForm from './LogsSearchForm';

export const AdminLogs = () => {
  return (
    <Layout route="admin-logs" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/admin`} className="back">Back</Link>
            <h1>Logs</h1>
          </div>

          <section>
            <LogsSearchForm />
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

export default AdminLogs;
