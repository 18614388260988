import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import PieChart from '../../../highcharts/PieChart';
import ProjectNav from './ProjectNav';

const data = {
  tracking: [
    {
      author: 'MW',
      name: 'Purchase additional safety furniture for onsite workers (new headcount)',
      created: '12/15/2021',
      cost: 180000,
      status: 'In Review',
      priority: 'Low',
      start: 0,
      end: 4,
      authorColor:"#18A0FB"
    },
    {
      author: 'EO',
      name: 'Add the FICA/SSN data security pack / software for multi-state data privacy / compliance formats',
      created: '12/15/2021',
      cost: 98000,
      status: 'Approved',
      priority: 'Medium',
      start: 1,
      end: 4,
      authorColor:"#fb7318"
    },
    {
      author: 'MW',
      name: 'Onboard additional developers and testers to catch up on lost time',
      created: '12/15/2021',
      cost: 350000,
      status: 'In Review',
      priority: 'High',
      start: 5,
      end: 6,
      authorColor:"#18A0FB"
    },
    {
      author: 'SH',
      name: 'Hire and train additional payroll staff to support the application at go-live',
      created: '12/15/2021',
      cost: 150000,
      status: 'Open',
      priority: 'Medium',
      start: 8,
      end: 10,
      authorColor:"#fbb318"
    },
    {
      author: 'EO',
      name: 'Release contingency back to budget for other projects / R&D ',
      created: '12/15/2021',
      cost: -800000,
      status: 'In Progress',
      priority: 'Medium',
      start: 9,
      end: 11,
      authorColor:"#fb7318"
    }
  ]
};

const calcStatus = (start, end, activeSlice) =>{
  if(activeSlice === end){
    return ['Approved', 'green']
  }
  if(activeSlice === end -1){
    return ['In Review', 'blue']
  }

  if(activeSlice === start){
    return ['Open', 'grey']
  }

  if(activeSlice > start && activeSlice < end){
    return ['In Progress', 'yellow']
  }
  return ['Open', 'grey']; //status color pairs as return
}

const requestLength = (sub, total) => {
  const baseWidth = sub / total * 100;
  if (baseWidth < 30) {
    return 30;
  }
  return baseWidth;
};

class ProjectDetailsScope extends Component {
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      tier2,
      endSliceIndex
    } = project || {};
    const setSliceIndex = value => setActiveSlice(value);

    const {
      scope
    } = tier2 || {};

    const {
      scope: scopeSlice,
      change,
      overview
    } = scope.slices[activeSliceIndex] || {};

    const series = [
      {
        data: [
          { name: 'Completed', y: scopeSlice.completed },
          { name: 'In Progress', y: scopeSlice.inProgress },
          { name: 'To Do', y: scopeSlice.toDo }
        ]
      }
    ];

    const style = endSliceIndex === activeSliceIndex ? {backgroundColor:'#5AC53A'} : {};
    return (
      <Layout route="project-details-t2 scope" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="scope"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <div className="scope-progress bg">
                <h3>Scope Progress</h3>
                <PieChart
                  height={'300px'}
                  width={'100%'}
                  containerName={'scope'}
                  backgroundColor={'transparent'}
                  data={series}
                />
                <ul className="lenged">
                  <li>
                    <div><span /> Completed</div>
                    <strong>{scopeSlice.completed || 0}</strong>
                  </li>
                  <li>
                    <div><span /> In Progress</div>
                    <strong>{scopeSlice.inProgress || 0}</strong>
                  </li>
                  <li>
                    <div><span /> To Do</div>
                    <strong>{scopeSlice.toDo || 0}</strong>
                  </li>
                </ul>
                <a href="./scope/Jira" className="">
                  View Sprint
                </a>
              </div>

              <Row>
                <Col>
                  <div className="requests bg">
                    <h3>Change Requests</h3>
                    <strong>{change.total}</strong>
                    <div style={{fontSize:"small", color:'white', marginBottom:'5px'}}>High</div>
                    <div
                      className="high"
                      style={{
                        width: `${requestLength(change.high, change.total)}%`
                      }}
                    >
                      <span />
                      <div>{change.high || '-'}</div>
                    </div>
                    <div style={{fontSize:"small", color:'white', marginTop:'10px'}}>Medium</div>
                    <div
                      className="medium"
                      style={{
                        width: `${requestLength(change.medium, change.total)}%`
                      }}
                    >
                      <span />
                      <div>{change.medium || '-'}</div>
                    </div>
                    <div style={{fontSize:"small", color:'white', marginTop:'10px', marginBottom:'5px'}}>Low</div>
                    <div
                      className="low"
                      style={{
                        width: `${requestLength(change.low, change.total)}%`
                      }}
                    >
                      <span />
                      <div>{change.low || '-'}</div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="overview bg">
                    <h3>Overview</h3>
                    <div>
                      <strong>{overview.open}</strong> Open
                    </div>
                    <div>
                      <strong>{overview.progress}</strong> In-Progress
                    </div>
                    <div>
                      <strong>{overview.completed}</strong> Completed
                    </div>
                    <div>
                      <strong>{overview.review}</strong> In-Review
                    </div>
                    <div>
                      <strong>{overview.approved}</strong> Approved
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="tracking bg">
                <h3>Change Tracking</h3>
                {(data.tracking || []).filter(({start, end})=>start <= activeSliceIndex && end >= activeSliceIndex).sort((a,b)=>a.start > b.start).map((m, index) => (
                  <div key={index} className="group">
                    <div style={{width:'74%'}}>
                      <div style={{marginBottom:'10px'}}>
                        <span className="author" style={{background:m.authorColor}}>{m.author}</span>
                        <strong>{m.name}</strong>
                      </div>
                      <div><span>Created:</span> {moment().subtract((endSliceIndex - activeSliceIndex) + m.end, 'months').format('MM/DD/YYYY')}</div>
                      <div>
                        <span>Cost:</span> {numeral(m.cost).format('$0,0')}
                      </div>
                    </div>
                    <div className="text-center" style={{minWidth:'85px'}}>
                      <span className={`status ${calcStatus(m.start,m.end,activeSliceIndex)[1]}`} style={style}>{calcStatus(m.start,m.end,activeSliceIndex)[0]}</span><br />
                      <span className={`priority ${m.priority}`}>
                        {m.priority}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsScope);
