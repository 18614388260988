import moment from 'moment';
import calcHorizontalGradient from './utils/calcHorizontalGradient';

export default (
  {
    projectData,
    knowledgeArea,
    valueType,
    name,
    hideMarkers,
    color,
    lineStyle,
    asPercent,
    maxPercentValue,
    hide,
    isGradient,
    showLastPoint,
    marker,
    isTrend,
    summaryTimeframe,
    isTier2
  }
) => {
  const {
    tier1,
    tier2,
    nowSliceIndex,
    nowSliceIndexTier1,
    endSliceIndex
  } = projectData || {};

  const {
    slices
  } = isTier2 ? tier2[knowledgeArea] : tier1[knowledgeArea] || {};

  const slicesLeft = endSliceIndex - (isTier2 ? nowSliceIndex : nowSliceIndexTier1);
  const filterOffset = summaryTimeframe - slicesLeft;
  const finalOffset = filterOffset < slicesLeft ? summaryTimeframe/2 : filterOffset

  const filteredSlices = (!summaryTimeframe || summaryTimeframe === 12) ? slices : (slices ||[]).slice((isTier2 ? nowSliceIndex : nowSliceIndexTier1) - (finalOffset) , (isTier2 ? nowSliceIndex : nowSliceIndexTier1) + (finalOffset) + 1 )

  const data = (filteredSlices || []).map((slice, index) => {
    const currentTime = moment().set('date', 1); //set day of month to 1 so that subtracting months is consistent
    const sliceDiff = ((!summaryTimeframe || summaryTimeframe === 12) ? index + (!isTier2 ? 0 : 1 ) : (index + finalOffset));
    const fakeForecastAddedMonths = isTier2 ? (endSliceIndex - nowSliceIndex) : (endSliceIndex - nowSliceIndexTier1)
    const sliceDate = currentTime.add((sliceDiff + 1) + fakeForecastAddedMonths, 'months'); //subtract or add based on now index
    const sliceYear = sliceDate.year();
    const sliceMonth = sliceDate.month();
    const valueX = Date.UTC(sliceYear, sliceMonth, 2);

    const valueY = (knowledgeArea === 'cost' && valueType === 'valueForecast' && endSliceIndex === index && !isTier2) ? 
    slice[
      'valueEAC'
    ]
    : 
    slice[
      !asPercent && name === 'Budget' ? 'valuePlanned' : valueType
    ];

    let finalY = knowledgeArea === 'cost' && asPercent
      ? valueY === null ? null : Math.round(valueY / maxPercentValue * 100)
      : valueY === null ? null : valueY;

    if (isTrend) {
      finalY = knowledgeArea === 'cost' && asPercent
        ? valueY === null ? null : slice['valueActualPercent']
        : valueY === null ? null : valueY;
    }

    const markerEnabled = !isNaN(showLastPoint) && showLastPoint === index
      ? true
      : false;

    return { x: valueX, y: finalY, marker: { enabled: markerEnabled } };
  });

  const seriesData = {
    name,
    data,
    marker: { enabled: hideMarkers },
    color,
    dashStyle: lineStyle,
    visible: !hide,
    marker: marker || {}
  };

  if (!isGradient || !(data || []).filter(element => element.y !== null).length) return seriesData;

  seriesData.color = calcHorizontalGradient({dataPoints:data});

  return seriesData;
};
