import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

const data = [
  {
    uid: 1,
    firstName: 'Alex',
    lastName: 'Strebeck',
    email: 'alex.strebeck@redcross.com',
    role: 'viewer'
  },
  {
    uid: 2,
    firstName: 'Matej',
    lastName: 'Janovcik',
    email: 'matej.janovcik@redcross.com',
    role: 'editor'
  },
  {
    uid: 3,
    firstName: 'Ben',
    lastName: 'Bluerock',
    email: 'ben.bluerock@redcross.com',
    role: 'admin'
  },
  {
    uid: 4,
    firstName: 'Ben',
    lastName: 'Redstone',
    email: 'ben.redstone@redcross.com',
    role: 'admin'
  }
];

class AdminUserRoles extends Component {
  render() {
    const { match, projects, showModal } = this.props;
    const { params } = match || {};
    const { uid } = params || {};
    const user = (data || []).find(u => u.uid == uid);
    return (
      <Layout route="admin-users" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin/users/${user.uid}`} className="back">Back</Link>
              <h1>Users</h1>
            </div>

            <section>
              <div className="user-detail">
                <span>{user.firstName[0]}{user.lastName[0]}</span>
                <div>
                  <strong>{user.firstName} {user.lastName}</strong>
                  <div>{user.email}</div>
                </div>
                <div className={`role ${user.role}`}>{user.role}</div>
              </div>

              <div className="form">
                <div className="control-label">User Role</div>

                <Form.Control as="select">
                  <option>Viewer</option>
                  <option>Editor</option>
                  <option>Admin</option>
                </Form.Control>

                <Link to={`/admin/users/${user.uid}`} className="btn">
                  Update Role
                </Link>

              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AdminUserRoles;
