import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/demo-dux';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import SearchForm from './SearchForm';

export const Search = ({ projects, toggleProjectFavorite }) => {
  return (
    <Layout route="search" isAuthorized>
      <Row>
        <Col md={12}>
          <h1>Search</h1>
          <SearchForm
            projects={projects}
            toggleProjectFavorite={toggleProjectFavorite}
          />
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(Search);
