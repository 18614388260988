import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/demo-dux';

class PieChart extends Component {
  constructor(props) {
    super(props);
  }

  highChartsRender = (
    {
      containerName,
      backgroundColor,
      series
    }
  ) => {
    return {
      chart: {
        type: 'pie',
        renderTo: containerName,
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: backgroundColor || '#FFFFFF'
      },
      title: {
        style: { display: 'none' }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        shared: true,
        borderWidth: 0,
        backgroundColor: 'transparent',
        padding: 0,
        shadow: false,
        formatter: function() {
          return '<div class="pie-tooltip">' +
            '<span class="value">' +
            numeral(this.percentage).format('0') +
            '%</span>' +
            '<div class="name">' +
            this.key +
            '</div>' +
            '</div>';
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: ['#5AC53A', '#FCB700', '#7D89FC'],
          center: ['50%', '50%'],
          innerSize: '60%',
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        }
      },
      series: series
    };
  };

  render() {
    const {
      height,
      width,
      containerName,
      isGradient,
      backgroundColor,
      data
    } = this.props || {};

    const series = data;

    const chart = this.highChartsRender({
      containerName,
      backgroundColor,
      series
    });

    return (
      <div
        id={containerName}
        style={{ width: width || '400px', height: height || '250px' }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
          containerProps={{ style: { width: '100%', height: '100%' } }}
        />
      </div>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(PieChart);
