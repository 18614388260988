import {
  getJson,
  postJson,
  patchJson
} from './fetch';

import localForage from 'localforage';

const apiBase = process.env.REACT_APP_API_BASE;

export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });