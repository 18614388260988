import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TrendChart from '../../../highcharts/TrendChartV2';
import colorGradient from '../../../highcharts/gradients';
import numeral from 'numeral';

export const ProjectHealth = ({ match, projects }) => {
  const { params } = match || {};
  const { id } = params || {};
  const project = (projects || []).find(p => p.id == id);
  const {
    nowSliceIndexTier1,
    health,
    tier1
  } = project || {};
  const {
    overall,
    scope,
    cost,
    quality,
    resources,
    communication,
    procurement,
    stakeholder,
    raid,
    schedule
  } = tier1 || {};

  return (
    <Layout route="project-health" isAuthorized>
      <Row>
        <Col md={12}>

          <section>

            <div className="header">
              <Link to={`/projects/${id}`} />
              <h1>Project Health</h1>
            </div>

            <div className="summary">
              <div style={{ color: colorGradient(health / 100) }}>{health}</div>
              <h2>Very Healthy</h2>
              <p>
                The overall project health for Project Name is based on the following knowledge areas scores.
              </p>
            </div>

            <div className="overview">
              <h2>Score Detail</h2>
              <ul>

                <li>
                  <Link to={`/projects/${id}/health/scope`}>
                    <strong>Scope</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${scope.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            scope.slices[nowSliceIndexTier1].valueActual / 100
                          )
                        }}
                      >
                        {scope.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/schedule`}>
                    <strong>Schedule</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${schedule.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            schedule.slices[nowSliceIndexTier1].valueActual /
                              100
                          )
                        }}
                      >
                        {schedule.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/cost`}>
                    <strong>Cost</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${cost.slices[nowSliceIndexTier1].valueActualPercent > 100 ? 100 : cost.slices[nowSliceIndexTier1].valueActualPercent}%`,
                          backgroundColor: colorGradient(
                            (cost.slices[
                              nowSliceIndexTier1
                            ].valueActualPercent > 100
                              ? 100
                              : cost.slices[
                                  nowSliceIndexTier1
                                ].valueActualPercent) / 100
                          )
                        }}
                      >
                        {cost.slices[nowSliceIndexTier1].valueActualPercent}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/quality`}>
                    <strong>Quality</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${quality.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            quality.slices[nowSliceIndexTier1].valueActual / 100
                          )
                        }}
                      >
                        {quality.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/resources`}>
                    <strong>Resources</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${resources.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            resources.slices[nowSliceIndexTier1].valueActual /
                              100
                          )
                        }}
                      >
                        {resources.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/communication`}>
                    <strong>Communication</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${communication.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            communication.slices[
                              nowSliceIndexTier1
                            ].valueActual / 100
                          )
                        }}
                      >
                        {communication.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/raid`}>
                    <strong>Risk (RAID)</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${raid.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            raid.slices[nowSliceIndexTier1].valueActual / 100
                          )
                        }}
                      >
                        {raid.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/procurement`}>
                    <strong>Procurement</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${procurement.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            procurement.slices[nowSliceIndexTier1].valueActual /
                              100
                          )
                        }}
                      >
                        {procurement.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to={`/projects/${id}/health/stakeholder`}>
                    <strong>Stakeholder</strong>
                    <div className="bar">
                      <div
                        className="bar-value"
                        style={{
                          width: `${stakeholder.slices[nowSliceIndexTier1].valueActual}%`,
                          backgroundColor: colorGradient(
                            stakeholder.slices[nowSliceIndexTier1].valueActual /
                              100
                          )
                        }}
                      >
                        {stakeholder.slices[nowSliceIndexTier1].valueActual}
                      </div>
                    </div>
                  </Link>
                </li>

              </ul>
            </div>

            <div className="projection">
              <h2>Health Projection</h2>
              <TrendChart
                projectData={project}
                asPercent={true}
                height={'200px'}
                width={'100%'}
                containerName={'health-projection-chart'}
                knowledgeArea={'overall'}
                backgroundColor={'transparent'}
                isGradient
                hideLegend
                showDatalabel
              />
            </div>

            <div className="about">
              <h2>About Score</h2>
              <div className="score">
                <div style={{ color: '#55BF3B' }}>85-100</div>
                <p>
                  Cumulative score shows project as being "Healthy." This indicates positive performance trends across multiple dimensions of the project.
                </p>
              </div>
              <div className="score">
                <div style={{ color: '#DDDF0D' }}>70-84</div>
                <p>
                  Cumulative score shows project as being "At Risk." This indicates several key performance metrics are facing challenges that require corrective action to return to a "Healthy" project state.
                </p>
              </div>
              <div className="score">
                <div style={{ color: '#DF5353' }}>0-69</div>
                <p>
                  Cumulative score shows project as being "Critical State" where action is required. This indicates escalation to executive key stakeholders to address critical blockers and setbacks as required.
                </p>
              </div>
            </div>

          </section>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectHealth);
