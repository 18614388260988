import React, { Component } from 'react';
import { Row, Col, Collapse, Tabs, Tab } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import ProjectNav from './ProjectNav';

const getArraySum = array => {
  return array.reduce((partialSum, a) => partialSum + a, 0);
};

const getBarWidth = (value, array) => {
  const sum = getArraySum(array);
  return `${value / sum * 100}%`;
};

const getValue = value => {
  return value > 0 ? value : '';
};

class ProjectDetailsRisk extends Component {
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      tier2
    } = project || {};
    const {
      raid
    } = tier2 || {};
    const {
      trigger,
      idle,
      age,
      risk,
      actions,
      issues,
      decisions
    } = raid.slices[activeSliceIndex] || {};
    const setSliceIndex = value => setActiveSlice(value);
    const activeMax = Math.max.apply(Math, [
      getArraySum(risk.active),
      getArraySum(actions.active),
      getArraySum(issues.active),
      getArraySum(decisions.active)
    ]);
    const archivedMax = Math.max.apply(Math, [
      getArraySum(risk.archived),
      getArraySum(actions.archived),
      getArraySum(issues.archived),
      getArraySum(decisions.archived)
    ]);

    const { innerWidth: width } = window;

    const fullScreenHeatMapStyle = width > 600;

    return (
      <Layout route="project-details-t2 risk" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="risk"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <div className="tabs bg">
                <Tabs defaultActiveKey="active">
                  <Tab eventKey="active" title="Active">
                    <div>
                      <ul>
                        <li>
                          <div className="label">
                            Risk - {getArraySum(risk.active)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${risk.active[0] / activeMax * 100}%`
                              }}
                            >
                              {risk.active[0] > 0
                                ? <span>{risk.active[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${risk.active[1] / activeMax * 100}%`
                              }}
                            >
                              {risk.active[1] > 0
                                ? <span>{risk.active[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${risk.active[2] / activeMax * 100}%`
                              }}
                            >
                              {risk.active[2] > 0
                                ? <span>{risk.active[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Actions - {getArraySum(actions.active)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${actions.active[0] / activeMax * 100}%`
                              }}
                            >
                              {actions.active[0] > 0
                                ? <span>{actions.active[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${actions.active[1] / activeMax * 100}%`
                              }}
                            >
                              {actions.active[1] > 0
                                ? <span>{actions.active[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${actions.active[2] / activeMax * 100}%`
                              }}
                            >
                              {actions.active[2] > 0
                                ? <span>{actions.active[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Issues - {getArraySum(issues.active)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${issues.active[0] / activeMax * 100}%`
                              }}
                            >
                              {issues.active[0] > 0
                                ? <span>{issues.active[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${issues.active[1] / activeMax * 100}%`
                              }}
                            >
                              {issues.active[1] > 0
                                ? <span>{issues.active[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${issues.active[2] / activeMax * 100}%`
                              }}
                            >
                              {issues.active[2] > 0
                                ? <span>{issues.active[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Decisions - {getArraySum(decisions.active)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${decisions.active[0] / activeMax * 100}%`
                              }}
                            >
                              {decisions.active[0] > 0
                                ? <span>{decisions.active[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${decisions.active[1] / activeMax * 100}%`
                              }}
                            >
                              {decisions.active[1] > 0
                                ? <span>{decisions.active[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${decisions.active[2] / activeMax * 100}%`
                              }}
                            >
                              {decisions.active[2] > 0
                                ? <span>{decisions.active[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="lenged">
                        <div><span /> Low</div>
                        <div><span /> Medium</div>
                        <div><span /> High</div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="archived" title="Archived">
                    <div>
                      <ul>
                        <li>
                          <div className="label">
                            Risk - {getArraySum(risk.archived)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${risk.archived[0] / archivedMax * 100}%`
                              }}
                            >
                              {risk.archived[0] > 0
                                ? <span>{risk.archived[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${risk.archived[1] / archivedMax * 100}%`
                              }}
                            >
                              {risk.archived[1] > 0
                                ? <span>{risk.archived[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${risk.archived[2] / archivedMax * 100}%`
                              }}
                            >
                              {risk.archived[2] > 0
                                ? <span>{risk.archived[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Actions - {getArraySum(actions.archived)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${actions.archived[0] / archivedMax * 100}%`
                              }}
                            >
                              {actions.archived[0] > 0
                                ? <span>{actions.archived[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${actions.archived[1] / archivedMax * 100}%`
                              }}
                            >
                              {actions.archived[1] > 0
                                ? <span>{actions.archived[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${actions.archived[2] / archivedMax * 100}%`
                              }}
                            >
                              {actions.archived[2] > 0
                                ? <span>{actions.archived[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Issues - {getArraySum(issues.archived)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${issues.archived[0] / archivedMax * 100}%`
                              }}
                            >
                              {issues.archived[0] > 0
                                ? <span>{issues.archived[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${issues.archived[1] / archivedMax * 100}%`
                              }}
                            >
                              {issues.archived[1] > 0
                                ? <span>{issues.archived[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${issues.archived[2] / archivedMax * 100}%`
                              }}
                            >
                              {issues.archived[2] > 0
                                ? <span>{issues.archived[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="label">
                            Decisions - {getArraySum(decisions.archived)}
                          </div>
                          <div className="chart">
                            <div
                              style={{
                                width: `${decisions.archived[0] / archivedMax * 100}%`
                              }}
                            >
                              {decisions.archived[0] > 0
                                ? <span>{decisions.archived[0]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${decisions.archived[1] / archivedMax * 100}%`
                              }}
                            >
                              {decisions.archived[1] > 0
                                ? <span>{decisions.archived[1]}</span>
                                : ''}
                            </div>
                            <div
                              style={{
                                width: `${decisions.archived[2] / archivedMax * 100}%`
                              }}
                            >
                              {decisions.archived[2] > 0
                                ? <span>{decisions.archived[2]}</span>
                                : ''}
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="lenged">
                        <div><span /> Low</div>
                        <div><span /> Medium</div>
                        <div><span /> High</div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="tabs bg">
                <Tabs defaultActiveKey="risk">
                  <Tab eventKey="risk" title="Risk">
                    <div className="heatmap">
                      <div className="legend left">Probability</div>
                      <div className="labels right">
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="map">
                        <div className="values">
                          <div className="group">
                            <div>{getValue(risk.map[0][0])}</div>
                            <div>{getValue(risk.map[0][1])}</div>
                            <div>{getValue(risk.map[0][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(risk.map[1][0])}</div>
                            <div>{getValue(risk.map[1][1])}</div>
                            <div>{getValue(risk.map[1][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(risk.map[2][0])}</div>
                            <div>{getValue(risk.map[2][1])}</div>
                            <div>{getValue(risk.map[2][2])}</div>
                          </div>
                        </div>
                      </div>
                      <div className={`labels ${fullScreenHeatMapStyle ? 'bottom-large' :  'bottom'}`} >
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="legend bottom">Impact</div>
                    </div>
                  </Tab>
                  <Tab eventKey="actions" title="Actions">
                    <div className="heatmap">
                      <div className="legend left">Probability</div>
                      <div className="labels right">
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="map">
                        <div className="values">
                          <div className="group">
                            <div>{getValue(actions.map[0][0])}</div>
                            <div>{getValue(actions.map[0][1])}</div>
                            <div>{getValue(actions.map[0][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(actions.map[1][0])}</div>
                            <div>{getValue(actions.map[1][1])}</div>
                            <div>{getValue(actions.map[1][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(actions.map[2][0])}</div>
                            <div>{getValue(actions.map[2][1])}</div>
                            <div>{getValue(actions.map[2][2])}</div>
                          </div>
                        </div>
                      </div>
                      <div className={`labels ${fullScreenHeatMapStyle ? 'bottom-large' :  'bottom'}`} >
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="legend bottom">Impact</div>
                    </div>
                  </Tab>
                  <Tab eventKey="issues" title="Issues">
                    <div className="heatmap">
                      <div className="legend left">Probability</div>
                      <div className="labels right">
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="map">
                        <div className="values">
                          <div className="group">
                            <div>{getValue(issues.map[0][0])}</div>
                            <div>{getValue(issues.map[0][1])}</div>
                            <div>{getValue(issues.map[0][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(issues.map[1][0])}</div>
                            <div>{getValue(issues.map[1][1])}</div>
                            <div>{getValue(issues.map[1][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(issues.map[2][0])}</div>
                            <div>{getValue(issues.map[2][1])}</div>
                            <div>{getValue(issues.map[2][2])}</div>
                          </div>
                        </div>
                      </div>
                      <div className={`labels ${fullScreenHeatMapStyle ? 'bottom-large' :  'bottom'}`} >
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="legend bottom">Impact</div>
                    </div>
                  </Tab>
                  <Tab eventKey="decisions" title="Decisions">
                    <div className="heatmap">
                      <div className="legend left">Probability</div>
                      <div className="labels right">
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="map">
                        <div className="values">
                          <div className="group">
                            <div>{getValue(decisions.map[0][0])}</div>
                            <div>{getValue(decisions.map[0][1])}</div>
                            <div>{getValue(decisions.map[0][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(decisions.map[1][0])}</div>
                            <div>{getValue(decisions.map[1][1])}</div>
                            <div>{getValue(decisions.map[1][2])}</div>
                          </div>
                          <div className="group">
                            <div>{getValue(decisions.map[2][0])}</div>
                            <div>{getValue(decisions.map[2][1])}</div>
                            <div>{getValue(decisions.map[2][2])}</div>
                          </div>
                        </div>
                      </div>
                      <div className={`labels ${fullScreenHeatMapStyle ? 'bottom-large' :  'bottom'}`} >
                        <div>Low</div>
                        <div>Medium</div>
                        <div>High</div>
                      </div>
                      <div className="legend bottom">Impact</div>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="status bg">
                <h3>Trigger</h3>
                <div className="red">
                  <strong>Overdue</strong>
                  <div>
                    <div
                      style={{
                        width: getBarWidth(trigger[0], trigger)
                      }}
                    />
                    <span>{trigger[0]}</span>
                  </div>
                </div>
                <div className="yellow">
                  <strong>Today</strong>
                  <div>
                    <div
                      style={{
                        width: getBarWidth(trigger[1], trigger)
                      }}
                    />
                    <span>{trigger[1]}</span>
                  </div>
                </div>
                <div className="green">
                  <strong>Upcoming</strong>
                  <div>
                    <div
                      style={{
                        width: getBarWidth(trigger[2], trigger)
                      }}
                    />
                    <span>{trigger[2]}</span>
                  </div>
                </div>
              </div>

              <div className="status bg">
                <h3>Idle</h3>
                <div className="red">
                  <strong>Stale</strong>
                  <div>
                    <div style={{ width: getBarWidth(idle[0], idle) }} />
                    <span>{idle[0]}</span>
                  </div>
                </div>
                <div className="yellow">
                  <strong>Mature</strong>
                  <div>
                    <div style={{ width: getBarWidth(idle[1], idle) }} />
                    <span>{idle[1]}</span>
                  </div>
                </div>
                <div className="green">
                  <strong>Recent</strong>
                  <div>
                    <div style={{ width: getBarWidth(idle[2], idle) }} />
                    <span>{idle[2]}</span>
                  </div>
                </div>
              </div>

              <div className="status bg">
                <h3>Age</h3>
                <div className="red">
                  <strong>Old</strong>
                  <div>
                    <div style={{ width: getBarWidth(age[0], age) }} />
                    <span>{age[0]}</span>
                  </div>
                </div>
                <div className="yellow">
                  <strong>Fresh</strong>
                  <div>
                    <div style={{ width: getBarWidth(age[1], age) }} />
                    <span>{age[1]}</span>
                  </div>
                </div>
                <div className="green">
                  <strong>New</strong>
                  <div>
                    <div style={{ width: getBarWidth(age[2], age) }} />
                    <span>{age[2]}</span>
                  </div>
                </div>
              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsRisk);
