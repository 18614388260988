import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Layout from '../../ui/Layout';

class SignUpSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = { notification: false };
  }
  componentDidMount() {
    this.showNotification = setInterval(
      () => this.setState({ notification: true }),
      500
    );
  }
  componentWillUnmount() {
    clearInterval(this.showNotification);
  }
  render() {
    const { user, error, location, postLogin } = this.props;
    const { notification } = this.state;
    clearInterval(this.showNotification);
    setTimeout(
      () => {
        this.setState({ notification: false });
      },
      5500
    );
    return (
      <Layout route="sign-up-success">
        <Link
          className={`notification ${notification ? 'show' : 'hide'}`}
          to="/complete-account"
        >
          <span>now</span>
          <div className="app">Mail</div>
          <strong>Crow's Nest™ App</strong>
          <div className="subject">Complete Your Account</div>
          <div className="preview">
            Verify your email address and complete your…
          </div>
        </Link>
        <Row className="justify-content-sm-center">
          <Col sm>
            <div className="icon" />
            <h2>Check your email</h2>
            <p>
              We’ve sent an email to gmcgovern@redcross.org to verify your account.
            </p>
            <Link to="/login" className="btn">Back to log in</Link>
          </Col>
        </Row>
        <Link className="continue" to="/complete-account">
          Continue with demo →
        </Link>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(SignUpSuccess);
