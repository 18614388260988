import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import ProjectNav from './ProjectNav';
import colorGradient from '../../../highcharts/gradients';
const mapRange = require('../../../highcharts/utils/mapRange').handler;

const data = {
  buffer: {
    days: 10
  },
  velocity: {
    commitment: 48,
    completed: 42
  },
  milestones: [
    { name: 'Milestone 1', date: '12/15/21', status: 'Complete' },
    { name: 'Milestone 2', date: '12/31/21', status: 'Critical' },
    { name: 'Milestone 3', date: '12/31/21', status: 'At Risk' },
    { name: 'Milestone 4', date: '12/31/21', status: 'Not Started' }
  ]
};

const getColor = status => {
  switch (status) {
    case 'IN PROGRESS':
      return 'blue';
    case 'COMPLETED':
      return 'green';
    case 'NOT STARTED':
      return 'grey';
    case 'AT RISK':
      return 'yellow';
    case 'CRITICAL':
      return 'red';
    default:
      return 'grey';
  }
};

class ProjectDetailsSchedule extends Component {
  render() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      startDate,
      endDate,
      tier2
    } = project || {};

    const {
      schedule
    } = tier2 || {};

    const {
      buffer,
      vCommit,
      vCompleted
    } = schedule.slices[activeSliceIndex] || {};

    const {
      bufferMax,
      mileStones
    } = schedule || {};

    const velocityPercent = vCommit / vCompleted;

    const max = 95;
    const min = 70;

    const velocityColor = colorGradient(
      mapRange(velocityPercent * 100, min, max, 0, 100),
      { red: 223, green: 83, blue: 83 }, // red '#DF5353'
      { red: 221, green: 223, blue: 13 }, // yellow '#DDDF0D'
      { red: 85, green: 191, blue: 59 } // green '#55BF3B'
    );
    const bufferColor = colorGradient(
      mapRange(buffer / bufferMax * 100, min, max, 0, 100),
      { red: 223, green: 83, blue: 83 }, // red '#DF5353'
      { red: 221, green: 223, blue: 13 }, // yellow '#DDDF0D'
      { red: 85, green: 191, blue: 59 } // green '#55BF3B'
    );

    const setSliceIndex = value => setActiveSlice(value);
    return (
      <Layout route="project-details-t2 schedule" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="schedule"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <Row>
                <Col>
                  <div className="timeline bg">
                    <h3>Timeline</h3>
                    <strong>
                      {moment(endDate).diff(moment(startDate), 'months') + 1}
                      {' '}
                      months
                    </strong>
                    <div className="details">
                      <div>
                        <b>{moment(startDate).format('MMM YYYY')}</b>
                        <span>Start</span>
                      </div>
                      <div>
                        <b>{moment(endDate).format('MMM YYYY')}</b>
                        <span>End</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="buffer bg">
                    <h3>Buffer Remaining</h3>
                    <span>
                      {buffer} days<small>out of 10 days</small>
                    </span>
                    <div className="bar">
                      <div className="legend">
                        <div>{buffer}</div>
                        <div>0</div>
                        <div />
                      </div>
                      <div
                        className="chart"
                        style={{
                          width: `${buffer / bufferMax * 100 / 2}%`,
                          left: `${(105 - buffer / bufferMax * 100) / 2}%`,
                          background: `${bufferColor}`
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="velocity bg">
                <h3>Velocity</h3>
                <p style={{ marginBottom: '50px' }}>
                  Work items completed over work items planned for the month or sprint
                </p>
                <div className="chart">
                  <div className="commitment">
                    Commitment
                  </div>
                  <div
                    className="completed"
                    style={{ right: `${100 - velocityPercent * 100}%` }}
                  >
                    Completed
                  </div>
                  <div
                    className="value"
                    style={{
                      width: `${velocityPercent * 100 - 0.5}%`,
                      background: `${velocityColor}`
                    }}
                  >
                    {vCommit}/{vCompleted}
                  </div>
                </div>
                <Link to={`/projects/${id}/schedule/Jira`}>
                  View Roadmap
                </Link>
              </div>

              <div className="milestones bg">
                <h3>Key Milestones</h3>
                {(mileStones || []).map((m, index) => {
                  return (
                    <div key={index} className="milestone">
                      <span
                        className={
                          `status-${getColor(m.status[activeSliceIndex])}`
                        }
                      >
                        {m.status[activeSliceIndex]}
                      </span>
                      <strong>{m.name}</strong>
                      <div><span>Date</span> {m.end.format('MMM YYYY')}</div>
                    </div>
                  );
                })}
              </div>

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  activeSliceIndex: state.demo.toJS().activeSliceIndex
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailsSchedule);
