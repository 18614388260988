import React, { Component } from 'react';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/demo-dux';
import { connect } from 'react-redux';
import ProjectNav from './ProjectNav';
import AreaChart from '../../../highcharts/AreaChart';
import TrendChart from '../../../highcharts/TrendChartV2';
import colorGradient from '../../../highcharts/gradients';
import classNames from 'classnames';

const mapRange = require('../../../highcharts/utils/mapRange').handler;

class ProjectDetails extends Component {
  componentWillMount() {
    const { projects, match, setActiveSlice, activeSliceIndex } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const project = (projects || []).find(p => p.id == id);
    setActiveSlice(activeSliceIndex);
  }
  render() {
    const {
      projects,
      match,
      setActiveSlice,
      activeSliceIndex,
      theme,
      user
    } = this.props;
    const { params } = match || {};
    const { id } = params || {};
    const { groups } = user || {};
    const project = (projects || []).find(p => p.id == id);
    const {
      name,
      description,
      assignedUsers,
      tier2
    } = project || {};
    const {
      cost,
      raid,
      scope,
      schedule
    } = tier2 || {};
    const setSliceIndex = value => setActiveSlice(value);
    const isAdmin = groups.includes('admin');
    return (
      <Layout route="project-details-t2 overall" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <div>
                <Link className="back" to="/" />
                <h1>
                  {name}
                  <small>{description}</small>
                </h1>
              </div>
              <div>
                <Link className="users" to={`/projects/${id}/users`}>
                  {(assignedUsers || []).map((u, index) => (
                    <span key={index} className="user">
                      {u.firstName[0]}{u.lastName[0]}
                    </span>
                  ))}
                  <span className="add">+</span>
                </Link>
              </div>
            </div>

            <section>

              <ProjectNav
                project={project}
                category="overall"
                handleChange={setSliceIndex}
                currentSlice={activeSliceIndex}
              />

              <Link to={`/projects/${id}/cost`} className="chart-link">
                <div>
                  <h3>Cost</h3>
                  <div className="trend">
                    Trend
                  </div>
                  <div
                    className="health"
                    style={{
                      backgroundColor: colorGradient(
                        mapRange(
                          cost.slices[activeSliceIndex].valueActualPercent
                        )
                      )
                    }}
                  >
                    {cost.slices[activeSliceIndex].valueActualPercent || 0}/100
                  </div>
                </div>
                <TrendChart
                  projectData={project}
                  asPercent={true}
                  height={'100px'}
                  width={'100%'}
                  containerName={'cost-chart'}
                  backgroundColor={'transparent'}
                  knowledgeArea={'cost'}
                  activeSliceIndex={activeSliceIndex}
                  theme={theme}
                  isGradient
                  hideLegend
                  hideXAxis
                  hideYAxis
                  isTier2
                />
              </Link>

              <Link to={`/projects/${id}/risk`} className="chart-link">
                <div>
                  <h3>Risk</h3>
                  <div className="trend">
                    Trend
                  </div>
                  <div
                    className="health"
                    style={{
                      backgroundColor: colorGradient(
                        mapRange(raid.slices[activeSliceIndex].valueActual)
                      )
                    }}
                  >
                    {raid.slices[activeSliceIndex].valueActual || 0}/100
                  </div>
                </div>
                <TrendChart
                  projectData={project}
                  asPercent={true}
                  height={'100px'}
                  width={'100%'}
                  containerName={'risk-chart'}
                  backgroundColor={'transparent'}
                  knowledgeArea={'raid'}
                  activeSliceIndex={activeSliceIndex}
                  theme={theme}
                  isGradient
                  hideLegend
                  hideXAxis
                  hideYAxis
                  isTier2
                />
              </Link>

              <Link to={`/projects/${id}/schedule`} className="chart-link">
                <div>
                  <h3>Schedule</h3>
                  <div className="trend">
                    Trend
                  </div>
                  <div
                    className="health"
                    style={{
                      backgroundColor: colorGradient(
                        mapRange(schedule.slices[activeSliceIndex].valueActual)
                      )
                    }}
                  >
                    {schedule.slices[activeSliceIndex].valueActual || 0}/100
                  </div>
                </div>
                <TrendChart
                  projectData={project}
                  asPercent={true}
                  height={'100px'}
                  width={'100%'}
                  containerName={'schedule-chart'}
                  backgroundColor={'transparent'}
                  knowledgeArea={'schedule'}
                  activeSliceIndex={activeSliceIndex}
                  theme={theme}
                  isGradient
                  hideLegend
                  hideXAxis
                  hideYAxis
                  isTier2
                />
              </Link>

              <Link to={`/projects/${id}/scope`} className="chart-link">
                <div>
                  <h3>Scope</h3>
                  <div className="trend">
                    Trend
                  </div>
                  <div
                    className="health"
                    style={{
                      backgroundColor: colorGradient(
                        mapRange(scope.slices[activeSliceIndex].valueActual)
                      )
                    }}
                  >
                    {scope.slices[activeSliceIndex].valueActual || 0}/100
                  </div>
                </div>
                <TrendChart
                  projectData={project}
                  asPercent={true}
                  height={'100px'}
                  width={'100%'}
                  containerName={'scope-chart'}
                  backgroundColor={'transparent'}
                  knowledgeArea={'scope'}
                  activeSliceIndex={activeSliceIndex}
                  theme={theme}
                  isGradient
                  hideLegend
                  hideXAxis
                  hideYAxis
                  isTier2
                />
              </Link>

              {isAdmin &&
                <Link to={`/projects/${id}/settings`} className="btn">
                  Project settings
                </Link>}

            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects,
  theme: state.demo.toJS().theme,
  activeSliceIndex: state.demo.toJS().activeSliceIndex,
  user: state.login.toJS().result
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetails);
