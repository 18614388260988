import React, { Component } from 'react';
import Highcharts from 'highcharts';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/demo-dux';
import seriesLoaderArea from './seriesLoaderArea';

/* usedto be identical to budget chart but I broke out as separate component so that we can add gradients ans such*/

class AreaChart extends Component {
  constructor(props) {
    super(props);
  }

  highChartsRender = (
    {
      seriesInfo,
      yAxisSide,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      backgroundColor
    }
  ) => {
    return Highcharts.chart({
      chart: {
        type: 'areaspline',
        renderTo: containerName,
        spacingBottom: 0,
        spacingTop: 30,
        spacingLeft: 0,
        spacingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: backgroundColor || '#FFFFFF'
      },
      title: {
        style: { display: 'none' }
      },
      yAxis: {
        height: '107%',
        endOnTick: false,
        opposite: yAxisSide,
        visible: !hideYAxis,
        title: {
          enabled: false
        },
        max: 105,
        min: 0,
        lineColor: '#DDDDDD',
        lineWidth: 1,
        gridLineColor: 'transparent',
        labels: {
          formatter: function() {
            return asPercent
              ? this.axis.defaultLabelFormatter.call(this) + '%'
              : '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }
      },
      xAxis: {
        offset: 10,
        type: 'datetime',
        visible: !hideXAxis,
        tickLength: 0,
        dateTimeLabelFormats: {
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b', //month formatted as month only
          year: '%Y'
        }
      },
      legend: {
        enabled: !hideLegend
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          states: {
            inactive: {
              opacity: 1
            }
          },
          events: {
            legendItemClick: function() {
              return false;
            }
          }
        }
      },
      series: (seriesInfo || []).map(series => series)
    });
  };

  componentDidMount() {
    const {
      projectData,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      hideActual,
      hideForecast,
      knowledgeArea,
      isGradient,
      yAxisSide,
      backgroundColor
    } = this.props || {};

    const {
      totalBudget
    } = projectData || {};

    const seriesActual = seriesLoaderArea({
      projectData,
      knowledgeArea,
      valueType: 'valueActual',
      name: 'Actual',
      lineStyle: 'Solid',
      hideMarkers: false,
      asPercent,
      maxPercentValue: totalBudget,
      hide: hideActual,
      isGradient
    }); //mainly for cost values

    const seriesForecast = seriesLoaderArea({
      projectData,
      knowledgeArea,
      valueType: 'valueForecast',
      name: 'Forecast',
      lineStyle: 'Shortdash',
      hideMarkers: false,
      asPercent,
      maxPercentValue: totalBudget,
      hide: hideForecast,
      isGradient
    }); //mainly for cost values

    const finalMax = asPercent ? 100 : totalBudget;

    this.highChartsRender({
      seriesInfo: [seriesForecast, seriesActual],
      yAxisSide: yAxisSide,
      dataMax: finalMax,
      asPercent,
      containerName,
      hideYAxis,
      hideXAxis,
      hideLegend,
      backgroundColor
    });
  }

  render() {
    const {
      height,
      width,
      containerName
    } = this.props || {};

    return (
      <div
        id={containerName}
        style={{ width: width || '400px', height: height || '250px' }}
      />
    );
  }
}

const mapState = state => ({
  projects: state.demo.toJS().projects
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(AreaChart);
