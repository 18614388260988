import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/demo-dux';
import { actionCreatorsModal } from '../../../state/modals-dux';
import classNames from 'classnames';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import Layout from '../../ui/Layout';

import LogoutModal from '../login/LogoutModal';

export const Profile = (
  { user, theme, avatar, setTheme, uploadAvatar, showModal }
) => {
  const { firstName, lastName, email } = user || {};
  return (
    <Layout route="profile" isAuthorized>
      <Row>
        <Col md={12}>
          <section>

            <Dropdown>
              <Dropdown.Toggle>
                <div className={classNames('avatar', { uploaded: avatar })}>
                  <span>+</span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => uploadAvatar(!avatar)}>
                  Photo Library
                </Dropdown.Item>
                <Dropdown.Item onClick={() => uploadAvatar(!avatar)}>
                  Take Photo
                </Dropdown.Item>
                <Dropdown.Item onClick={() => uploadAvatar(!avatar)}>
                  Choose File
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <h2>Details</h2>

            <div className="group">
              <span>Edit</span>
              <h3>Full Name</h3>
              <div>{firstName} {lastName}</div>
            </div>
            <div className="group">
              <span>Edit</span>
              <h3>Email Address</h3>
              <div>{email}</div>
            </div>
            <div className="group">
              <span>Edit</span>
              <h3>Password</h3>
              <div>**********</div>
            </div>

            <h2 style={{ marginTop: '40px' }}>Appearance</h2>
            <div className="theme-switch">
              <Button
                onClick={() => setTheme('dark')}
                className={theme === 'dark' ? 'active' : ''}
              >
                Dark Mode
              </Button>
              <Button
                onClick={() => setTheme('light')}
                className={theme === 'light' ? 'active' : ''}
              >
                Light Mode
              </Button>
            </div>

            <Button onClick={() => showModal('LogoutModal')}>
              Log out
            </Button>

          </section>

          <LogoutModal />
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  user: state.login.toJS().result,
  theme: state.demo.toJS().theme,
  avatar: state.demo.toJS().avatar
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      ...actionCreatorsModal
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Profile);
