import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import UsersSearchForm from './UsersSearchForm';

export const AdminUsers = () => {
  const users = [];
  return (
    <Layout route="admin-users" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/admin`} className="back">Back</Link>
            <h1>Users</h1>
            <Link className="add" to={`/admin/users/add`}>
              <span>+</span>
            </Link>
          </div>

          <UsersSearchForm />

        </Col>
      </Row>
    </Layout>
  );
};

export default AdminUsers;
