import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

const SearchForm = props => {
  const {
    handleSubmit,
    submitting,
    search,
    projects,
    toggleProjectFavorite
  } = props;
  return (
    <div>

      <form onSubmit={handleSubmit}>
        <Field
          name="search"
          type="text"
          component={FormField.Input}
          label="Search"
          insetLabel
          icon="search"
        />
      </form>

        <div className="project-list">
          {(projects || [])
            .filter(
              p => p.name.toLowerCase().indexOf((search || '').toLowerCase()) !== -1
            )
            .map((project, index) => (
              <div key={index} className="project">
                <Link to={`/projects/${1}`}>
                  <strong>{project.name}</strong>
                  <small>{project.description}</small>
                </Link>
                <div>
                  <button
                    onClick={() =>
                      toggleProjectFavorite({
                        id: project.id,
                        value: !project.favorite
                      })}
                  >
                    {!project.favorite &&
                      <span className="favorite">Favorite</span>}
                    {project.favorite && <span className="added">Added</span>}
                  </button>
                </div>
              </div>
            ))}
        </div>

    </div>
  );
};

const formName = 'search-form';
const selector = formValueSelector(formName);

const mapState = state => {
  const search = selector(state, 'search');
  return {
    search
  };
};

const form = reduxForm({
  form: formName
})(SearchForm);

export default connect(mapState)(form);
