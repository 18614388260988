import React, { Component } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

class AdminPremissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminAddUser: true,
      adminEditUser: true,
      adminDeleteUser: true,
      adminManage: true,
      adminLogs: true,
      colAddUser: true,
      colEditUser: false,
      colDeleteUser: false,
      colManage: false,
      colLogs: false,
      rewAddUser: true,
      rewEditUser: true,
      rewDeleteUser: false,
      rewManage: true,
      rewLogs: false
    };
  }
  render() {
    const {
      adminAddUser,
      adminEditUser,
      adminDeleteUser,
      adminManage,
      adminLogs,
      colAddUser,
      colEditUser,
      colDeleteUser,
      colManage,
      colLogs,
      rewAddUser,
      rewEditUser,
      rewDeleteUser,
      rewManage,
      rewLogs
    } = this.state;
    const toggleOption = (key, value) => this.setState({ [key]: !value });
    return (
      <Layout route="admin-premissions" isAuthorized>
        <Row>
          <Col md={12}>

            <div className="header">
              <Link to={`/admin`} className="back">Back</Link>
              <h1>Premissions</h1>
            </div>

            <section>
              <Tabs defaultActiveKey="admin">
                <Tab eventKey="admin" title="Admin">
                  <div>

                    <div className="group">
                      <div>
                        Add Users
                        <div
                          className={`toggle ${adminAddUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('adminAddUser', adminAddUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Edit Users
                        <div
                          className={`toggle ${adminEditUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('adminEditUser', adminEditUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Delete Users
                        <div
                          className={
                            `toggle ${adminDeleteUser ? 'active' : ''}`
                          }
                          onClick={() =>
                            toggleOption('adminDeleteUser', adminDeleteUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Manage Projects
                        <div
                          className={`toggle ${adminManage ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('adminManage', adminManage)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        View Logs
                        <div
                          className={`toggle ${adminLogs ? 'active' : ''}`}
                          onClick={() => toggleOption('adminLogs', adminLogs)}
                        />
                      </div>
                    </div>

                  </div>
                </Tab>
                <Tab eventKey="collaborators" title="Collaborators">
                  <div>

                    <div className="group">
                      <div>
                        Add Users
                        <div
                          className={`toggle ${colAddUser ? 'active' : ''}`}
                          onClick={() => toggleOption('colAddUser', colAddUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Edit Users
                        <div
                          className={`toggle ${colEditUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('colEditUser', colEditUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Delete Users
                        <div
                          className={`toggle ${colDeleteUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('colDeleteUser', colDeleteUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Manage Projects
                        <div
                          className={`toggle ${colManage ? 'active' : ''}`}
                          onClick={() => toggleOption('colManage', colManage)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        View Logs
                        <div
                          className={`toggle ${colLogs ? 'active' : ''}`}
                          onClick={() => toggleOption('colLogs', colLogs)}
                        />
                      </div>
                    </div>

                  </div>
                </Tab>
                <Tab eventKey="reviewer" title="Reviewer">
                  <div>

                    <div className="group">
                      <div>
                        Add Users
                        <div
                          className={`toggle ${rewAddUser ? 'active' : ''}`}
                          onClick={() => toggleOption('rewAddUser', rewAddUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Edit Users
                        <div
                          className={`toggle ${rewEditUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('rewEditUser', rewEditUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Delete Users
                        <div
                          className={`toggle ${rewDeleteUser ? 'active' : ''}`}
                          onClick={() =>
                            toggleOption('rewDeleteUser', rewDeleteUser)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        Manage Projects
                        <div
                          className={`toggle ${rewManage ? 'active' : ''}`}
                          onClick={() => toggleOption('rewManage', rewManage)}
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div>
                        View Logs
                        <div
                          className={`toggle ${rewLogs ? 'active' : ''}`}
                          onClick={() => toggleOption('rewLogs', rewLogs)}
                        />
                      </div>
                    </div>

                  </div>
                </Tab>
              </Tabs>
            </section>

          </Col>
        </Row>
      </Layout>
    );
  }
}

export default AdminPremissions;
