import { actionNamespace } from './defaults';
import { fromJS } from 'immutable';
import moment from 'moment';
import projects from './_projects';

const SET_THEME = `${actionNamespace}/SET_THEME`;
const SET_AVATAR = `${actionNamespace}/SET_AVATAR`;
const REMOVE_NOTIFICATION = `${actionNamespace}/REMOVE_NOTIFICATION`;
const REMOVE_PROJECT = `${actionNamespace}/REMOVE_PROJECT`;
const FAVORITE_PROJECT = `${actionNamespace}/FAVORITE_PROJECT`;
const SET_MY_PROJECTS = `${actionNamespace}/SET_MY_PROJECTS`;
const SET_ACTIVE_SLICE = `${actionNamespace}/SET_ACTIVE_SLICE`;

const today = new Date();
let yesterday = new Date();
let twoDaysAgo = new Date();
let twoDaysAgoTier2 = new Date();

const defaultState = fromJS({
  account: {},
  avatar: false,
  theme: 'dark',
  activeSliceIndex: 11,
  notifications: [
    {
      id: 9,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Cutover) has been COMPLETED',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 8,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Cutover) has been STARTED',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 7,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Testing) has been STARTED',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 6,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) has been COMPLETED.',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 5,
      project: 1,
      name: 'Money Train',
      description: '@Alex Strebeck  Have issue resolved, decision worked. Back on -track and timeline restored.',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 4,
      project: 1,
      name: 'Money Train',
      description: '@Alex Strebeck  Hitting some pushback, can we schedule a meetup?',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 3,
      project: 1,
      name: 'Money Train',
      description: '@Alex Strebeck  Phase II has begun. Everything seems to be tracking well.',
      date: twoDaysAgo.setDate(twoDaysAgo.getDate() - 3),
      tier:1
    },
    {
      id: 2,
      project: 1,
      name: 'Money Train',
      description: '@Alex Strebeck  Do you want a high overpass, or detailed summary thus far?',
      date: yesterday.setDate(yesterday.getDate() - 1),
      tier:1
    },
    {
      id: 1,
      project: 1,
      name: 'Money Train',
      description: 'The approved project has been STARTED.',
      date: today,
      tier:1
    },
    {
      id: 30,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Cutover) has been COMPLETED.',
      date: today,
      tier:2
    },
    {
      id: 29,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Cutover) has been STARTED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 1),
      tier:2
    },
    {
      id: 28,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Testing) has been COMPLETED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 2),
      tier:2
    },
    {
      id: 27,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Testing) has been STARTED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 26,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) has been COMPLETED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 25,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) has been de-escalated to IN PROGRESS.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 24,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) is escalated to CRITICAL.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 23,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) is escalated to AT RISK.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 22,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Mapping, Development, & QA) has been STARTED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 21,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Data Cleansing) has been COMPLETED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 20,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Data Cleansing) has been STARTED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 19,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Kick-Off & Onboarding) has been COMPLETED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 3),
      tier:2
    },
    {
      id: 18,
      project: 1,
      name: 'Money Train',
      description: 'Milestone (Kick-Off & Onboarding) has been STARTED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 250),
      tier:2
    },
    {
      id: 17,
      project: 1,
      name: 'Money Train',
      description: 'Project has been APPROVED.',
      date: twoDaysAgoTier2.setDate(twoDaysAgoTier2.getDate() - 600),
      tier:2
    },
  ],
  projects: projects
});

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_THEME:
      return state.merge({ theme: payload });
    case SET_AVATAR:
      return state.merge({ avatar: payload });
    case REMOVE_NOTIFICATION:
      const notifications = state
        .toJS()
        .notifications.filter(n => n.id !== payload);
      return state.merge({ notifications: notifications });
    case SET_MY_PROJECTS:
      let myProjects = state.toJS().projects;
      myProjects.forEach((p, index) => {
        if ((payload.projects || []).indexOf(p.id) !== -1) {
          p.favorite = true;
        }
      });
      return state.merge({ projects: myProjects });
    case FAVORITE_PROJECT:
      let favProjects = state.toJS().projects;
      favProjects.forEach((p, index) => {
        if (payload.id === p.id) {
          p.favorite = payload.value;
        }
      });
      return state.merge({ projects: favProjects });
    case REMOVE_PROJECT:
      const projects = state.toJS().projects.filter(p => p.id !== payload);
      return state.merge({ projects: projects });
    case SET_ACTIVE_SLICE:
      return state.merge({ activeSliceIndex: payload });
    default:
      return state;
  }
};

export const actionCreators = {
  setTheme: payload => ({ type: SET_THEME, payload }),
  uploadAvatar: payload => ({ type: SET_AVATAR, payload }),
  removeNotification: payload => ({ type: REMOVE_NOTIFICATION, payload }),
  removeProject: payload => ({ type: REMOVE_PROJECT, payload }),
  toggleProjectFavorite: payload => ({ type: FAVORITE_PROJECT, payload }),
  setMyProjects: payload => ({ type: SET_MY_PROJECTS, payload }),
  setActiveSlice: payload => ({ type: SET_ACTIVE_SLICE, payload })
};
