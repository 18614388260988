import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

export const AdminDashboard = () => {
  return (
    <Layout route="admin-dashboard" isAuthorized>
      <Row>
        <Col md={12}>

          <div className="header">
            <div>
              <div className="logo" />
              <h1>Crow's Nest™ App</h1>
            </div>
          </div>

          <section>
            <Row>
              <Col>

                <Link to="/admin/users" className="admin-link users">
                  Users
                </Link>

              </Col>
              <Col>

                <Link to="/admin/services" className="admin-link services">
                  Connectors
                </Link>

              </Col>
            </Row>
            <Row>
              <Col>

                <Link to="/admin/logs" className="admin-link logs">
                  Logs
                </Link>

              </Col>
              <Col>

                <Link to="/admin/security" className="admin-link security">
                  Security
                </Link>

              </Col>
            </Row>
            <Row>
              <Col>

                <Link
                  to="/admin/premissions"
                  className="admin-link premissions"
                >
                  Premissions
                </Link>

              </Col>
              <Col>

                <Link to="/admin/billing" className="admin-link billing">
                  Billing
                </Link>

              </Col>
            </Row>
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

export default AdminDashboard;
