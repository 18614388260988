import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

// Shared
import ProjectUsers from './ProjectUsers';
import ProjectSettings from './ProjectSettings';

// Tier 1
import T1ProjectDetails from './t1/ProjectDetails';
import T1ProjectHealth from './t1/ProjectHealth';
import T1ProjectHealthScore from './t1/ProjectHealthScore';

// Tier 2
import T2ProjectDetails from './t2/ProjectDetails';
import T2ProjectScope from './t2/ProjectDetailsScope';
import T2ProjectSchedule from './t2/ProjectDetailsSchedule';
import T2ProjectCost from './t2/ProjectDetailsCost';
import T2ProjectQuality from './t2/ProjectDetailsQuality';
import T2ProjectResources from './t2/ProjectDetailsResources';
import T2ProjectCommunication from './t2/ProjectDetailsCommunication';
import T2ProjectRisk from './t2/ProjectDetailsRisk';
import T2ProjectProcurement from './t2/ProjectDetailsProcurement';
import T2ProjectStakeholder from './t2/ProjectDetailsStakeholder';
import T2Jira from './t2/ProjectJiraMock';
import T2JiraSchedule from './t2/ProjectJiraMockSchedule';

export const ProjectDetails = ({ match, user }) => {
  const { groups } = user || [];
  if (groups.includes('tier-2')) {
    return (
      <Switch>
        <Route exact path={`${match.url}/:id`} component={T2ProjectDetails} />
        <Route
          exact
          path={`${match.url}/:id/overall`}
          component={T2ProjectDetails}
        />
        <Route
          exact
          path={`${match.url}/:id/scope`}
          component={T2ProjectScope}
        />
        <Route
          exact
          path={`${match.url}/:id/scope/Jira`}
          component={T2Jira}
        />
       
        <Route
          exact
          path={`${match.url}/:id/schedule`}
          component={T2ProjectSchedule}
        />

        <Route
          exact
          path={`${match.url}/:id/schedule/Jira`}
          component={T2JiraSchedule}
        />
        
        <Route exact path={`${match.url}/:id/cost`} component={T2ProjectCost} />
        <Route
          exact
          path={`${match.url}/:id/quality`}
          component={T2ProjectQuality}
        />
        <Route
          exact
          path={`${match.url}/:id/resources`}
          component={T2ProjectResources}
        />
        <Route
          exact
          path={`${match.url}/:id/communication`}
          component={T2ProjectCommunication}
        />
        <Route exact path={`${match.url}/:id/risk`} component={T2ProjectRisk} />
        <Route
          exact
          path={`${match.url}/:id/procurement`}
          component={T2ProjectProcurement}
        />
        <Route
          exact
          path={`${match.url}/:id/stakeholder`}
          component={T2ProjectStakeholder}
        />
        <Route exact path={`${match.url}/:id/users`} component={ProjectUsers} />
        <Route
          exact
          path={`${match.url}/:id/settings`}
          component={ProjectSettings}
        />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={`${match.url}/:id`} component={T1ProjectDetails} />
        <Route
          exact
          path={`${match.url}/:id/health`}
          component={T1ProjectHealth}
        />
        <Route
          exact
          path={`${match.url}/:id/health/:score`}
          component={T1ProjectHealthScore}
        />
        <Route exact path={`${match.url}/:id/users`} component={ProjectUsers} />
        <Redirect to="/" />
      </Switch>
    );
  }
};

const mapState = state => ({
  user: state.login.toJS().result
});

export default connect(mapState)(ProjectDetails);
