import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export const ProjectUsers = ({ match, projects }) => {
  const { params } = match || {};
  const { id } = params || {};
  const project = (projects || []).find(p => p.id == id);
  const {
    assignedUsers
  } = project || {};
  return (
    <Layout route="project-users">
      <Row>
        <Col md={12}>

          <div className="header">
            <Link to={`/projects/${id}`} className="back">Back</Link>
            <h1>Users</h1>
          </div>

          <section>
            <ul>
              {(assignedUsers || []).map((u, index) => (
                <li key={index}>
                  <span>{u.firstName[0]}{u.lastName[0]}</span>
                  <div>
                    <strong>{u.firstName} {u.lastName}</strong>
                    <div>{u.email}</div>
                  </div>
                </li>
              ))}
            </ul>
            {/*
              <button className="btn btn-primary">
                Add User
              </button>
            */}
          </section>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => ({
  projects: state.demo.toJS().projects
});

export default connect(mapState)(ProjectUsers);
