import moment from 'moment';
import calcHorizontalGradient from './utils/calcHorizontalGradient';

export default (
  {
    projectData,
    knowledgeArea,
    valueType,
    name,
    hideMarkers,
    color,
    lineStyle,
    asPercent,
    maxPercentValue,
    hide,
    isGradient,
    showPattern
  }
) => {
  const {
    tier2,
    nowSliceIndex
  } = projectData || {};

  const {
    slices
  } = tier2[knowledgeArea] || {};

  const data = (slices || []).map((slice, index) => {
    const currentTime = moment().set('date', 1); //set day of month to 1 so that subtracting months is consistent
    const sliceDiff = index - nowSliceIndex;
    const sliceDate = currentTime.add(sliceDiff, 'months'); //subtract or add based on now index
    const sliceYear = sliceDate.year();
    const sliceMonth = sliceDate.month();
    const valueX = Date.UTC(sliceYear, sliceMonth, 1);

    const valueY = slice[valueType];

    const finalY = knowledgeArea === 'cost' && asPercent
      ? valueY === null ? null : slice['valueActualPercent']
      : valueY === null ? null : valueY;

    return { x: valueX, y: finalY };
  });

  const seriesData = {
    name,
    data,
    marker: { enabled: hideMarkers },
    // color,
    dashStyle: lineStyle,
    visible: !hide,
    color: {
      pattern: {
        path: {
          d: 'M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7',
          strokeWidth: 3
        },
        height: 6,
        width: 6,
        r: 4,
        color: '#d4483d'
      }
    }
  };

  if (!isGradient) return seriesData;

  seriesData.color = calcHorizontalGradient({dataPoints:data});

  return seriesData;
};
