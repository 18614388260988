import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducer } from 'redux-form';
import modals from './modals-dux';
import fetchDux from './fetch-dux';
import demo from './demo-dux';

const fetchReducers = {};
Object.keys(fetchDux).forEach(key => {
  fetchReducers[key] = fetchDux[key].reducer;
});

const reducer = combineReducers(
  Object.assign({}, fetchReducers, {
    form: formReducer,
    modals,
    demo
  })
);

const watchers = () =>
  Object.keys(fetchDux).map(key => fetchDux[key].watchForRequest());

function* sagas() {
  return yield watchers();
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension
      ? window.devToolsExtension(
          {
            // trace: true
          }
        )
      : f => f
  )
);

sagaMiddleware.run(sagas);

export default store;
